function EventListController($scope, EventService) {
    $scope.tableParams = {
        service: EventService,
        hasCheckbox: false,
        selected: [],
        columns: {
            id: {label:"Cód"},
            eventName: {label:"Evento"},
            options: {label: "Ações", order:false, actions: [{label:'Convidar clientes', icon:'person_add', route: '/eventos/convidados/', class: 'md-accent'}]}
        }
    };

}

events.controller('EventListController', EventListController);