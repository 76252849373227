function OnUploadFile() {
    return {
        restrict: 'A',
        scope: {
            onUploadFile: '='
        },
        link: function ($scope, $element, $attributes) {
            var maxSize = $attributes.maxSize;
            $element.bind('change', function () {
                if ($element[0].files.length > 0) {
                    var file = $element[0].files[0];
                    $scope.onUploadFile(file, maxSize);
                    $element[0].value = null;
                    $scope.$apply();
                }
            });
        }
    }
}

base.directive('onUploadFile', [OnUploadFile]);
