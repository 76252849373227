sac.factory('SACService', ['$http', 'Middleware', function ($http, Middleware) {

    var services = {
        sac: Middleware.getUrl('sac/'),
        categories: Middleware.getUrl('sac/categories/'),
        observation: Middleware.getUrl('/sac/observation/'),
        filter: Middleware.getUrl('notification/filter/')
    };

    function create(sac)
    {
        var json = JSON.stringify(sac);

        return $http.post(services.sac, json).then(function(result) {

            /** TODO: serviço para tratamento de exceções do middleware **/
            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getAll(limit, page, order, filter)
    {
        var url = services.sac + 'list/' + limit + "/" + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getById(id)
    {
        return $http.get(services.sac +"get/" +id).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getCategories()
    {
        return $http.get(services.categories).then(function(result) {
            return result.data.data;
        });
    }

    function getListCategories()
    {
        return $http.get(services.categories + 'list').then(function(result) {
            return result.data.data;
        });
    }

    return {
        create: create,
        getAll: getAll,
        getById: getById,
        getCategories: getCategories,
        getListCategories : getListCategories
    };

}]);