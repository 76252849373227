function EventService($http, Middleware) {
    var service = {
        url: Middleware.getUrl('events/')
    };

    function getAll(page, limit, order, filter) {
        var url = service.url + "list/" + limit + '/' + page + '?order='+ order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url).then(function (response) {
            return response.data.data;
        });
    }

    function getById(id) {
        var url = service.url + "get/" + id;

        return $http.get(url).then(function (response) {
            return response.data.data;
        });
    }

    function create(page) {
        var data = JSON.stringify(page);
        return $http.post(service.url, data);
    }

    function edit(genericContent) {
        var data = JSON.stringify(genericContent);
        return $http.post(service.url + genericContent.id, data);
    }

    function remove(id) {
        return $http.delete(service.url + id);
    }

    function getAllToSearch() {
        var url = service.url + "list/1000";

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data.data;
        });
    }

    return {
        create: create,
        edit: edit,
        getAll: getAll,
        getById: getById,
        remove: remove,
        getAllToSearch: getAllToSearch
    };
}

events.factory('EventService', ['$http', 'Middleware', EventService]);