function JobOffers($http, Middleware)
{
    const services = {
        opportunity: Middleware.getUrl('opportunity/')
    };

    function create(opportunity) {
        return $http.post(services.opportunity, opportunity);
    }

    function edit(opportunity) {
        return $http.post(services.opportunity + opportunity.id, opportunity);
    }

    function getAll(limit, page, order, filter) {
        var url = services.opportunity + "list/" + limit + '/' + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function getAllToSearch(limit, page, order, filter) {
        var url = services.opportunity + "list/" + limit + '/' + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data.data;
        });
    }

    function getById(id) {
        return $http.get(services.opportunity + 'get/' + id).then(function (response) {
            var opportunity = new OpportunityModel();
            opportunity.fromService(response.data.data);
            return opportunity;
        });
    }

    function getDetails(id) {
        return $http.get(services.opportunity + 'details/' + id, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function remove(id) {
        return $http.delete(services.opportunity + id);
    }

    return {
        create: create,
        edit: edit,
        getAll: getAll,
        getById: getById,
        remove: remove,
        getDetails: getDetails,
        getAllToSearch: getAllToSearch
    };
}

jobs.factory('JobOffers', ['$http', 'Middleware', JobOffers]);
