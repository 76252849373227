/***
 * Define base event handler for Route Change
 * This event handler is used for ACL
 */
app.run(['$rootScope', 'PageInformation', 'GridFilters', 'Breadcrumb','$interpolate', '$location',
    function($rootScope, PageInformation, GridFilters, Breadcrumb, $interpolate, $location) {
    $rootScope.$on('$routeChangeStart', RouteChangeStartEvent);

    function RouteChangeStartEvent(event, next, current)
    {
        resetCurrentInformation();

        if (!next || !next.$$route) {
            return routeNotFound();
        }

        if (!next.$$route.allow) {
            return routeNotAllowed(event);
        }

        prepareNextRoute(next.$$route, next.params);
    }

    function resetCurrentInformation()
    {
        PageInformation.setPageHeader('');
        Breadcrumb.clear();
    }

    function routeNotFound()
    {
        $location.path('/error/404');
    }

    function routeNotAllowed(event)
    {
        event.preventDefault();
        $location.path('/');
    }

    function prepareNextRoute(route, params)
    {
        buildRouteBreadcrumb(route, params);
        setRouteInformation(route);
        clearUnusedGridFilters(route);
    }

    function setRouteInformation(route)
    {
        PageInformation.setPageHeader(route.pageName || '');
    }

    function buildRouteBreadcrumb(nextRoute, params)
    {
        var breadcrumb = nextRoute.breadcrumb;
        var name = nextRoute.name;

        if (breadcrumb && breadcrumb.parent) {
            while (breadcrumb) {
                var url = $interpolate(breadcrumb.url || '')(params);
                Breadcrumb.pushRoute(breadcrumb.label, url, name);
                var route = app.routeProvider.getRouteByName(breadcrumb.parent);
                breadcrumb = route ? route.breadcrumb : false;
                name = route ? route.name : false;
            }
        }
    }

    function clearUnusedGridFilters(route)
    {
        GridFilters.deleteUnused();
        GridFilters.setCurrentRouteName(route.name);
    }
}]);