function Breadcrumb()
{
    const state = {routes: [{name: 'Início', route: '/', routeName: 'index'}]};
    var breadcrumbRouteMap = {};

    function pushRoute(name, route, routeName) {
        state.routes.push({name: name, route: route, routeName: routeName});
        breadcrumbRouteMap[routeName] = true;
    }

    function clear() {
        state.routes.splice(0);
        breadcrumbRouteMap = {};
    }

    function getState() {
        return state;
    }

    function isOnCurrentBreadcrumb(name) {
        return breadcrumbRouteMap.hasOwnProperty(name);
    }

    return {
        clear: clear,
        getState: getState,
        pushRoute: pushRoute,
        isOnCurrentBreadcrumb: isOnCurrentBreadcrumb
    };
}

base.factory('Breadcrumb', [Breadcrumb]);