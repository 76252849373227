function EventGuestListController($scope, EventGuestService, $routeParams, EventService, PageInformation, $mdDialog, SimpleDialog, Loader, $q) {
    EventGuestService.setEventId($routeParams.id);

    EventService.getById($routeParams.id).then(function(data) {
        PageInformation.setPageHeader('Eventos - ' + data.eventName);
        $scope.event.maxParticipantsLimit = data.maxParticipantsLimit;
        $scope.event.invitationsSent = data.invitationsSent;
        $scope.event.inscriptionsClosed = data.inscriptionsClosed;
        $scope.event.dateLimitInvite = moment(data.dateLimitInvite.date).format('DD/MM/YYYY');
    });

    $scope.tableParams = {
        service: EventGuestService,
        initialOrder: 'name',
        hasCheckbox: true,
        selected: [],
        columns: {
            personDocument: {label:"CPF"},
            name: {label:"Nome", searchType: false},
            invitationDate: {label: "Convidado em", searchType: false},
            invitedByName: {label: "Convidado Por", searchType: false}
        }
    };

    $scope.storeInvitationInfo = EventGuestService.storeInvitationInfo;
    $scope.eventId = $routeParams.id;
    $scope.event = {
        maxParticipantsLimit: 0,
        invitationsSent: 0
    };

    function onDelete(event) {
        if ($scope.tableParams.selected.length == 0) {
            showDeleteError(event);
            return;
        }

        showDeleteConfirm(event);
    }

    function deleteSelected(event) {
        var selected = $scope.tableParams.selected;

        removePerson(selected);
    }

    function showDeleteError(event) {
        SimpleDialog.show('Nenhum registro selecionado', 'Selecione pelo menos um registro para remoção.', 'Ok', event);
    }

    function removePerson(selected) {
        Loader.show();

        var promises = [];

        for (var i = 0; i < selected.length; i++) {
            var item = selected[i];
            promises.push(EventGuestService.removePerson($routeParams.id, item.personDocument));
        }

        var promise = $q.all(promises);

        promise.then(function () {
            SimpleDialog.show('Sucesso', 'Registro(s) removido(s) com sucesso.', 'Ok', event);
        });

        promise.catch(function () {
            SimpleDialog.show('Ocorreu um erro', 'Um ou mais registros não foram removidos.', 'Ok', event);
        });

        promise.finally(function () {
            Loader.hide();
            $scope.tableParams.reloadTable = true;
        });

        $scope.tableParams.selected = [];
    }

    function showDeleteConfirm(event) {
        var confirm = $mdDialog.confirm()
            .title('Remover registros')
            .textContent('Tem certeza que deseja remover os registro(s) selecionado(s)?')
            .ariaLabel('Remover registros')
            .targetEvent(event)
            .ok('Remover')
            .cancel('Cancelar');

        var promise = $mdDialog.show(confirm);

        promise.then(function () {
            deleteSelected(event);
        });
    }

    $scope.onDelete = onDelete;
}

events.controller('EventGuestListController', EventGuestListController);