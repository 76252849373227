/***
 * Cookie based session storage service
 */
base.factory('SessionStorage', ['$cookies', function($cookies) {

    /**
     * Clears the session
     */
    function clear() {
        var stored = $cookies.getAll();
        var keys = Object.keys(stored);

        var size = keys.length;
        for(var i = 0; i < size; i++) {
            $cookies.remove(keys[i]);
        }
    }

    /**
     * StoreModel a session variable
     * @param key Data identifier
     * @param value
     */
    function setValue(key, value) {
        $cookies.putObject(key, value);
    }

    /**
     * Get session variable value
     * @param key Data identifier
     * @returns {*} Stored value
     */
    function getValue(key) {
        return $cookies.getObject(key);
    }

    return {
        clear: clear,
        getValue: getValue,
        setValue: setValue
    };

}]);