function SearchService($http, Middleware) {
    var services = {
        cpf: Middleware.getUrl('search/cpf/'),
        cnpj: Middleware.getUrl('search/cnpj/'),
        address: Middleware.getUrl('search/address/'),
        person: Middleware.getUrl('search/person/')
    };

    function findCpf(cpf,birthDate) {
        cpf = cpf.replace(/[^0-9]/g, '');
        birthDate = birthDate.replace(/\//g, '-');

        return $http.get(services.cpf + cpf + "/" +birthDate, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function findCnpj(cnpj) {
        cnpj = cnpj.replace(/[^0-9]/g, '');

        return $http.get(services.cnpj + cnpj, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function findAddress(search) {
        return $http.get(services.address + search, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function getPersonByDocument(document) {
        return $http.get(services.person + document).then(function (response) {
            return response.data.data;
        });
    }

    return {
        findCpf:findCpf,
        findCnpj:findCnpj,
        findAddress:findAddress,
        getPersonByDocument: getPersonByDocument
    };
}

base.factory('SearchService', ['$http', 'Middleware', SearchService]);