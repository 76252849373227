function ProductModel() {
    this.id = null;
    this.name = "";
    this.subtitle = "";
    this.description = "";
    this.active = true;
    this.internalCode = null;
    this.valueToSell = null;
    this.promotionalValue = null;
    this.categories = [];
    this.featured = null;
    this.stockSetupId = null;
    this.image = null;
    this.previewCategories = [];
    this.authorizationStatus = null;
    this.authorizationReplies = [];
    this.categoriesDeleted = [];
    this.exhibitionDateStart = null;
    this.exhibitionDateEnd = null;
}

ProductModel.prototype.fromService = function (data) {
    this.id = data.id;
    this.name = data.name;
    this.subtitle = data.subtitle;
    this.description = data.description;
    this.internalCode = data.internalCode;
    this.valueToSell = data.valueToSell;
    this.promotionalValue = data.promotionalValue;
    this.categories = getIdCategories(data.categories);
    this.previewCategories = data.categories;
    this.featured = data.featured;
    this.active = data.isActive;
    this.stockSetupId = data.stockSetupId;
    this.image = data.image;
    this.authorizationStatus = data.authorizationStatus;
    this.authorizationReplies = data.authorizationRequestReplies;
    this.exhibitionDateStart = formatedDateTime(data.exhibitionDateStart);
    this.exhibitionDateEnd = formatedDateTime(data.exhibitionDateEnd);
};

ProductModel.prototype.toFormData = function () {
    var formData = new FormData();

    formData.append('name', this.name);
    formData.append('subtitle', this.subtitle);
    formData.append('description', this.description);
    formData.append('internalCode', this.internalCode);
    formData.append('valueToSell', this.valueToSell);
    formData.append('promotionalValue', this.promotionalValue);
    formData.append('categories', JSON.stringify(this.categories));
    formData.append('featured', this.featured);
    formData.append('active', this.active);
    formData.append('stockSetupId', this.stockSetupId);
    formData.append('categoriesDeleted', JSON.stringify(this.categoriesDeleted));
    formData.append('exhibitionDateStart', this.exhibitionDateStart);
    formData.append('exhibitionDateEnd', this.exhibitionDateEnd);

    return formData;
};

function getIdCategories(categories)
{
    var aux = [];

    for(var x in categories){
        aux.push(categories[x].id);
    }

    return aux;
}

function formatedDateTime(date) {
    var dateTime = {};
    if (date) {
        dateTime.date = moment(date.date).format('DD/MM/YYYY');
        dateTime.time = moment(date.date).format('DD/MM/YYYY HH:mm');
        return dateTime;
    }

    return '';
}

