function ImgOnLoad($timeout) {
    return {
        restrict: 'A',
        link: function(scope, element, attrs) {
            element.bind('load', function() {
                $timeout(function() {
                    scope.$apply(attrs.imageonload)(true);
                }, 500);
            });
            element.bind('error', function(){
                $timeout(function() {
                    scope.$apply(attrs.imageonload)(false);
                }, 500);
            });
        }
    };
};

base.directive('imageonload', ImgOnLoad);