function CropperAndFilter(Loader, $timeout, AppGlobalVars) {
    return {
        scope: {
            imageWidth: "@",
            hintsWidth: "@",
            hintsHeight: "@",
            initialImage: "@",
            slimId: "@",
            imageCroppedAndFilteredHidden: "@",
            coverImageCroppedAndFiltered: "=",
            controllerImageUpdate: '&',
            withImageFilters: "=",
            inputName: "@?"
        },
        controller: ['$scope', '$element', '$attrs', function (scope, element, attrs) {
            scope.coverImageCropped = null;
            scope.coverImageCroppedAndFiltered = null;
            scope.firstImageUploaded = null;
            scope.imageFilter = '';
            scope.showFilters = false;
            scope.changed = false;
            scope.enableFilters = false;
            scope.auxImage = null;
            scope.finalImage = null;

            if(!scope.inputName) {
                scope.inputName = "image";
            }

            if(scope.initialImage) {
                var image = document.createElement('img');
                image.setAttribute('crossOrigin', 'anonymous');
                image.setAttribute('src', scope.initialImage);

                image.onload = function () {
                    var imgBase64 = getBase64Image(image);
                    scope.auxImage = image;
                    scope.finalImage = scope.initialImage;

                    scope.firstImageUploaded = imgBase64;
                    scope.coverImageCroppedAndFiltered = imgBase64;
                    scope.coverImageCropped = imgBase64;
                    scope.showFilters = true;

                    scope.$apply();
                };
            }

            scope.forceResolution = {
                width: scope.hintsWidth,
                height: scope.hintsHeight
            };

            scope.slimOptions = {
                ratio : 'free',
                size : 'free',
                withOptions: false,
                withoutOptions: false
            };

            if(scope.forceResolution.width && scope.forceResolution.height && scope.forceResolution.width !== undefined && scope.forceResolution.width !== "false" && scope.forceResolution.height !== undefined && scope.forceResolution.height !== "false") {
                scope.slimOptions._ratio = scope.forceResolution.height / scope.forceResolution.width;
                scope.slimOptions.ratio = "1:" + (scope.forceResolution.height / scope.forceResolution.width).toString();
                scope.slimOptions.size = scope.forceResolution.width + ',' + scope.forceResolution.height;
                scope.slimOptions.withOptions = true;
            }else{
                scope.slimOptions.withoutOptions = true;
            }

            scope.coverImage = {
                init: function (data, slim) {
                    slim._options.statusImageTooSmall = "<p>A imagem deve ter a resolução de $0 pixels.</p>";
                    slim._options.statusFileSize = "<p>O arquivo é muito grande. Máximo permitido é de $0 MB.</p>";
                    slim._options.buttonCancelLabel = "Cancelar";
                    slim._options.buttonConfirmLabel = "Confirmar";
                    slim._options.maxFileSize = AppGlobalVars.getMaxImageUploadSize().megabytes;
                    slim._options.upload = false;

                    if(scope.forceResolution.width && scope.forceResolution.height && scope.forceResolution.width !== undefined && scope.forceResolution.width !== "false" && scope.forceResolution.height !== undefined && scope.forceResolution.height !== "false") {
                        slim._options.minSize = scope.forceResolution;
                        slim._options.size = scope.forceResolution;
                        slim._options.forceSize = scope.forceResolution;
                        slim._options.ratio = scope.slimOptions.ratio;
                        slim._ratio = scope.slimOptions._ratio;
                        slim._options.crop = scope.slimOptions.crop;
                    }

                    $timeout(function() {
                        var srcImageData = document.querySelector('#'+scope.slimId + ' .slim-result>img.in').src;

                        scope.firstImageUploaded = srcImageData;
                        scope.coverImageCroppedAndFiltered = srcImageData;
                        attrs.coverImageCroppedAndFiltered = srcImageData;
                        scope.coverImageCropped = srcImageData;

                        scope.$apply();
                    }, 0);
                },
                service: function (file, progress, success, failure, slim) {
                    var reader = new FileReader();
                    reader.readAsDataURL(file[0]);

                    reader.onload = function () {
                        scope.coverImageCropped = reader.result;
                        scope.firstImageUploaded = scope.coverImageCropped;

                        scope.coverImageCroppedAndFiltered = scope.coverImageCropped;
                        scope.imageFilter = '';
                        scope.showFilters = true;

                        scope.finalImage = scope.coverImageCropped;

                        scope.$apply();
                        success();
                    };

                    reader.onerror = function (error) {
                        console.log('Error: ', error);
                        failure();
                    };
                },
                confirm: function(file, image, meta) {
                    var tempEnableFilters = scope.enableFilters;

                    scope.enableFilters = false;
                    scope.$apply();

                    if(tempEnableFilters){
                        $timeout(function() {
                            scope.firstImageUploaded = scope.coverImageCroppedAndFiltered;
                            scope.enableFilters = true;

                            scope.$apply();
                        }, 0);
                    }
                }
            };

            scope.imageFilterPresets = {
                "normal": "Sem filtro",
                "sepia": "Sépia",
                "desaturate": "Escala de cinza",
                "vintage": "Vintage",
                "greenish": "Greenish",
                "reddish": "Reddish"
            };

            scope.showImageFilters = function(){
                $timeout(function() {
                    var srcImageData = scope.auxImage.src;

                    scope.firstImageUploaded = srcImageData;
                    scope.coverImageCroppedAndFiltered = srcImageData;
                    attrs.coverImageCroppedAndFiltered = srcImageData;
                    scope.coverImageCropped = srcImageData;

                    scope.enableFilters = !scope.enableFilters;

                    scope.$apply();
                }, 0);
            };

            scope.applyFilter = function(filter) {
                if(scope.imageFilter != filter) {
                    Loader.show();
                }
                scope.imageFilter = filter;
            };

            scope.coverImageRemoved = function() {
                scope.coverImageCropped = null;
                scope.coverImageCroppedAndFiltered = null;
                scope.firstImageUploaded = null;
                scope.imageFilter = '';
                scope.showFilters = false;
                scope.$apply();
            };

            scope.imgOnLoad = function(status) {
                $timeout(function() {
                    var srcImageData = document.querySelector('#filters-' + scope.slimId + ' #' + scope.imageCroppedAndFilteredHidden).src;

                    if(srcImageData != scope.coverImageCroppedAndFiltered) {
                        angular.element(document.querySelector('#'+scope.slimId + ' .slim-result>img.in')).attr('src', srcImageData);
                        angular.element(document.querySelector('#'+scope.slimId + ' .slim-result>img.out')).attr('src', srcImageData);
                    }

                    scope.coverImageCroppedAndFiltered = srcImageData;
                    attrs.coverImageCroppedAndFiltered = srcImageData;
                    scope.finalImage = srcImageData;

                    scope.$apply();
                }, 0);

                $timeout(function() {
                    Loader.hide();
                }, 0);
            };

            function getBase64Image(img) {
                var canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;

                var ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                return canvas.toDataURL("image/png");
            }

        }],
        replace: false,
        templateUrl: 'app/modules/base/directives/CropperAndFilter/views/template.html',
        restrict: 'E',
        link: function (scope, element, attrs, ctrl) {
            scope.$watch('finalImage', function(newValue) {
                scope.controllerImageUpdate({ param: newValue });
            });
        }
    };
}

base.directive('cropperAndFilter', CropperAndFilter);