function KnowledgeBaseService($http, Middleware)
{
    var services = {
        knowledgeBase: Middleware.getUrl('knowledge-base')
    };

    function getAll(pagination, filter)
    {
        const params = getParams([filter, {order: pagination.order}]);
        const url = services.knowledgeBase.concat('/list/internal--admin--knowledge-base/' + pagination.limit + '/' + pagination.page);

        return $http.get(url, {params: params}).then(function(response) {
            return response.data.data;
        });
    }

    function getByIdentifier(identifier)
    {
        const url = services.knowledgeBase.concat('/get/' + identifier);
        return $http.get(url).then(function(response) {
            return response.data.data;
        });
    }

    function getParams(params)
    {
        const mergedParams = params.reduce(function(result, current) { return angular.extend(result, current) }, {});
        return removeNullAndEmptyFields(mergedParams);
    }

    function removeNullAndEmptyFields(object)
    {
        const keys = Object.keys(object);
        const validKeys = keys.filter(function(key) { return valueNotNullOrEmpty(object[key]) });
        return validKeys.reduce(function(result, key) { result[key] = object[key]; return result; }, {});
    }

    function valueNotNullOrEmpty(value)
    {
        return value !== undefined && value !== null && value !== '';
    }

    return {
        getAll: getAll,
        getByIdentifier: getByIdentifier
    };
}

knowledgeBase.factory('KnowledgeBaseService', ['$http', 'Middleware', KnowledgeBaseService]);