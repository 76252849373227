function JobOpportunity($http, Middleware)
{
    var services = {
        opportunity: Middleware.getUrl('opportunity/'),
        interestArea: Middleware.getUrl('interest-area/')
    };

    function create(opportunity)
    {
        var json = JSON.stringify(opportunity);

        return $http.post(services.opportunity, json).then(function(data) {
            return data;
        });
    }

    function edit(id, opportunity)
    {
        var json = JSON.stringify(opportunity);

        return $http.post(services.opportunity  + id, json).then(function(data) {
            return data;
        });
    }

    function remove(id)
    {
        return $http.delete(services.opportunity + id).then(function(data) {
            return data;
        });
    }

    function getById(id) {
        return $http.get(services.opportunity + 'get/' + id).then(function(data) {
            var jobOpportunity = new OpportunityModel();
            jobOpportunity.fromService(data.data.data);
            return jobOpportunity;
        });
    }

    function getAll(limit, page, order, filter)
    {
        var url = services.opportunity + 'list/' + limit + '/' + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url).then(function(data) {
            return data.data.data;
        });
    }

    function getInterestArea(limit, page, order, filter) {
        var url = services.interestArea + 'list/' + limit + '/' + page + '?active=1&order=' + order + '&type[]=2&type[]=3';

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data.data;
        });
    }

    return {
        create: create,
        edit: edit,
        remove: remove,
        getById: getById,
        getAll: getAll,
        getInterestArea: getInterestArea
    };
}

jobs.factory('JobOpportunity', ['$http', 'Middleware', JobOpportunity]);
