base.factory('Notifications', function ($http, $interval, $mdToast, $notification, Middleware) {
    var intervalStarted = false;
    var calledSummary = false;
    var calledNotifications = false;

    var oldUnreadQuantity = 0;
    var intervalVar = null;

    var notificationType = {
        Newsletter: 1,
        ServiceRequest: 2,
        ServiceRequestReply: 3,
        ProductReply: 5
    };

    var currentPage = 1;

    var service = {
        notifications: Middleware.getUrl('notification')
    };

    var actualUnread = 0;
    var notifications = {data: [], notified: false, unread: ''};
    var summary = {
        newsletter: {count: 0, message: ''},
        ProductReply: {count: 0, message: ''},
        serviceRequestReply: {count: 0, message: ''}
    };

    var summaryChanges = {value:0};
    var onlyUnread = false;
    var loading = {value:0};

    function setOnlyUnread(onlyUnreadParam)
    {
        onlyUnread = onlyUnreadParam;
        getData();
    }

    function markAllAsRead()
    {
        $http.post(service.notifications+'/mark-all-as-read').then(getData());
    }

    function updateFromPage() {
        if(!calledSummary && !calledNotifications) {
            if(intervalVar) {
                $interval.cancel(intervalVar);
                intervalStarted = false;
                intervalVar = null;
            }

            getData();
        }
    }

    function dieNotifications()
    {
        $interval.cancel(intervalVar);
        intervalStarted = false;
        intervalVar = null;
        actualUnread = 0;

        notifications.data = [];
        notifications.notified = false;
        notifications.unread = '';
    }

    function getSummary(reset) {
        if(reset) {
            actualUnread = 0;
        }

        summaryChanges.value = 0;
        resetSummary();

        calledSummary = true;

        $http.get(service.notifications+'/summary?onlyUnread='+onlyUnread).then(function (response) {
            var data = response.data.data;

            for(var i = 0; i < data.length; i++) {
                if(data[i].type == notificationType.Newsletter) {
                    summary.newsletter.count = data[i].quantity;
                    summary.newsletter.message = summary.newsletter.count == 1 ? "Você possui 1 novo comunicado." : "Você possui " + summary.newsletter.count + " novos comunicados.";

                    actualUnread += data[i].quantity;
                } else if(data[i].type == notificationType.ServiceRequestReply) {
                    summary.serviceRequestReply.count = data[i].quantity;
                    summary.serviceRequestReply.message = summary.serviceRequestReply.count == 1 ? "Você possui 1 nova resposta de solicitação de serviço." : "Você possui " + summary.serviceRequestReply.count + " novas respostas de solicitações de serviços.";

                    actualUnread += data[i].quantity;
                }else if(data[i].type == notificationType.ProductReply) {
                    summary.ProductReply.count = data[i].quantity;
                    summary.ProductReply.message = summary.ProductReply.count == 1 ? "Você possui 1 nova resposta de autorização de produto." : "Você possui " + summary.ProductReply.count + " novas resposta de autorização de produto";

                    actualUnread += data[i].quantity;
                }
            }

            notifications.unread = actualUnread;

            if(reset) {
                if(actualUnread > oldUnreadQuantity) {
                    oldUnreadQuantity = actualUnread;
                    showToastr();
                }
            }

            calledSummary = false;
        }).finally(function(){
            summaryChanges.value = 1;
        });

        return summary;
    }

    function resetSummary()
    {
        for(var i in summary) {
            summary[i].count = 0;
            summary[i].message = '';
        }
    }

    function getNotification(page, reset, callback) {
        if(reset) {
            page = 1;
           currentPage = 1;
        }

        loading.value = 1;

        calledNotifications = true;

        $http.get(service.notifications+'/list/10/'+page+'?onlyUnread='+onlyUnread).then(function (response) {
            var aux = response.data.data;
            if(!reset)
                notifications.data = notifications.data.concat(aux.data);
            else {
                notifications.data = aux.data;
            }
            notifications.lastPage = aux.lastPage;
            notifications.currentPage = currentPage;

            for(var i = 0; i < notifications.data.length; i++) {
                if(notifications.data[i].type == notificationType.Newsletter) {
                    notifications.data[i].icon = 'contact_mail';
                } else if(notifications.data[i].type == notificationType.ServiceRequest || notifications.data[i].type == notificationType.ServiceRequestReply) {
                    notifications.data[i].icon = 'build';
                    notifications.data[i].typeName = 'Requisição de serviço';
                } else if(notifications.data[i].type == notificationType.ProductReply) {
                    notifications.data[i].icon = 'shopping_cart';
                }
            }

            calledNotifications = false;
        }).finally(function(){
            loading.value = 0;

            if(callback) {
                callback();
            }
        });

        if(!intervalStarted) {
            intervalStarted = true;
            interval();
        }

        return notifications;
    }

    function showToastOnFocus() {
        $mdToast.show(
            $mdToast.simple()
                .textContent(actualUnread == 1 ? 'Você possui 1 nova notificação' : 'Você possui ' + actualUnread + ' novas notificações.')
                .action('x')
                .position('bottom right')
                .hideDelay(5000)
        );
    }

    function showToastOnBlur() {
        $notification('', {
            body: actualUnread == 1 ? 'Você possui 1 nova notificação' : 'Você possui ' + actualUnread + ' novas notificações.',
            lang: 'pt-br',
            tag: 'crmall',
            icon: 'assets/img/logo.png',
            delay: 4000, // in ms
            focusWindowOnClick: true
        });
    }

    function showToastr() {
        if (document.hasFocus() ) {
            showToastOnFocus();
        } else {
            showToastOnBlur();
        }
    }

    function nextPage() {
        currentPage++;
        return getNotification(currentPage, false, false);
    }

    function getData() {
        getNotification(currentPage, true, function() {
            getSummary(true);
        });
    }

    function interval() {
        intervalVar = $interval(function() {
            getData();
        }, 300000);
    }

    function setAsRead(id) {
        return $http.post(service.notifications+'/set-as-read/'+id).then(function (response) {
            return response;
        });
    }

    function decreaseUnreadCount() {
        notifications.unread--;
    }

    function loadMore() {
        return nextPage();
    }

    function getNotificationByIdentifier(identifier) {
        for(var i = 0; i < notifications.data.length; i++) {
            if(notifications.data[i].identifier == identifier) {
                return notifications.data[i];
            }
        }
    }

    function getNotificationById(id) {
        for(var i = 0; i < notifications.data.length; i++) {
            if(notifications.data[i].id == id) {
                return notifications.data[i];
            }
        }
    }

    return {
        getNotification: getNotification,
        notifications: notifications,
        summary: summary,
        summaryChanges: summaryChanges,
        loading: loading,
        nexPage: nextPage,
        setAsRead: setAsRead,
        decreaseUnreadCount: decreaseUnreadCount,
        loadMore: loadMore,
        getSummary: getSummary,
        showToastr: showToastr,
        getNotificationByIdentifier: getNotificationByIdentifier,
        getNotificationById: getNotificationById,
        updateFromPage: updateFromPage,
        dieNotifications: dieNotifications,
        setOnlyUnread: setOnlyUnread,
        markAllAsRead: markAllAsRead
    };
});