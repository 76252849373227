function ImageModel() {
    this.id = null;
    this.identifier = null;
    this.title = '';
    this.startDate = moment().format('DD/MM/YYYY HH:mm');
    this.endDate = '';
    this.image = '';
    this.exibitionOrder = 1;
    this.isActive = true;
    this.url = '';
    this.urlRedirect = '';
    this.description = '';
    this.showSite = true;
    this.showApp = true;
    this.coverImageCroppedAndFiltered = null;
    this.tags = [];
    this.maxSize = null;
}

ImageModel.prototype.setStartDate = function (date) {
    this.startDate = moment(date).format('DD/MM/YYYY HH:mm');
};

ImageModel.prototype.setEndDate = function (date) {
    this.endDate = moment(date).format('DD/MM/YYYY HH:mm');
};

ImageModel.prototype.fromService = function (data) {
    this.id = data.id;
    this.identifier = data.identifier;
    this.title = data.title;

    if (data.startDate) {
        this.setStartDate(data.startDate.date);
    }

    if (data.endDate) {
        this.setEndDate(data.endDate.date);
    }

    this.exibitionOrder = data.exibitionOrder;
    this.isActive = data.isActive;

    this.url = data.url;

    this.urlRedirect = data.urlRedirect;
    this.description = data.description;
    this.tags = data.tags;
    this.showSite = data.showSite;
    this.showApp = data.showApp;
};
