notification.factory('Notification', ['$http', 'Middleware', function ($http, Middleware) {
    var services = {
        notification: Middleware.getUrl('newsletter/'),
        newsletterCategory: Middleware.getUrl('newsletter-category/')
    };

    return {
        getAll: function (limit, page, order, filter) {
            var url = services.notification + 'list/' + limit + "/" + page + '?order=' + order;

            if(filter){
                url += "&"+filter;
            }
            
            return $http.get(url, {cancellable: true}).then(function (result) {

                var notifications = null;

                if (result.data.cod === 200) {
                    notifications = result.data.data;
                }

                return notifications;

            });
        },
        getNotificationById : function(id) {
            return $http.get(services.notification.concat('get/'+id), {cancellable: true}).then(function(result) {

                var notification = null;

                if (result.data.cod === 200) {
                    notification = result.data.data;
                }

                return notification;

            });
        },
        setAsRead : function(id) {
            return $http.post(services.notification.concat('set-as-read/'+id), {cancellable: true}).then(function(result) {
                return true;
            });
        },
        getCategories : function(){
        return $http.get(services.newsletterCategory + "list/500", {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }
    };
}]);