function ProfileController($scope, MyAccount, Loader, User, SearchService, StatesService, $mdToast)
{
    $scope.enableToInitCropper = false;
    $scope.coverImageCroppedAndFiltered = null;
    $scope.user = new UserModel();

    var TYPEPHONE = {
        PERSONAL: 1,
        CONTACT: 3
    };

    $scope.coverImageUpdate = function(param) {
        $scope.coverImageCroppedAndFiltered = param;
    };

    $scope.states = StatesService.getAll();

    function getProfile()
    {
        Loader.show();
        var response = MyAccount.getProfile(User.getUser().id);
        response.then(onLoadProfile);
    }

    function onLoadProfile(response)
    {
        $scope.enableToInitCropper = true;
        $scope.user = response;

        if($scope.user.picture) {
            $scope.coverImageCroppedAndFiltered = $scope.user.picture;
        }

        Loader.hide();
    }

    function changeCep(){
        if ($scope.user.address.zipCode !== undefined && $scope.user.address.zipCode !== "") {
            Loader.show();

            var promise = SearchService.findAddress($scope.user.address.zipCode);

            promise.then(function(data){
                if(data.length > 0 ){
                    var address = data[0];

                    $scope.user.address = {
                        "zipCode": address.zipCode,
                        "streetName": address.streetName,
                        "complement": address.complement,
                        "neighborhood": address.neighborhood,
                        "number": parseInt(address.addressNumber),
                        "city": address.city,
                        "state": address.stateCode,
                        "country": ""
                    };
                }
            });

            promise.finally(function(){
                Loader.hide();
            });
        }
    }

    function save(form) {
        form.$setSubmitted();


        if (form.$invalid) {
            angular.element(document.querySelector('input.ng-invalid'))[0].focus();
            return;
        }

        $scope.user.coverImage = $scope.coverImageCroppedAndFiltered;
        $scope.user.route = 'myAccount';

        if ($scope.user.phone.phone !== null) {
            $scope.user.phone.type = TYPEPHONE.CONTACT;
            $scope.user.phones.push($scope.user.phone);
        }

        if ($scope.user.celPhone.phone !== null) {
            $scope.user.celPhone.type = TYPEPHONE.PERSONAL;
            $scope.user.phones.push($scope.user.celPhone);
        }

        Loader.show();

        var promise = MyAccount.edit($scope.user.id, $scope.user);
        promise.then(toastThen);
        promise.catch(toastCatch);
        promise.finally(function() {
            Loader.hide();
        });
    }

    function toastThen() {
        $mdToast.show(
            $mdToast.simple()
                .textContent('Dados alterados com sucesso!')
                .position('bottom right')
                .hideDelay(4000)
        );
    }

    function toastCatch() {
        $mdToast.show(
            $mdToast.simple()
                .textContent('Ocorreu um erro, Tente novamente mais tarde!')
                .position('bottom right')
                .hideDelay(4000)
        );
    }


    getProfile();
    $scope.changeCep = changeCep;
    $scope.save = save;
}

profile.controller('ProfileController',
    ['$scope', 'MyAccount', 'Loader', 'User', 'SearchService', 'StatesService', '$mdToast', ProfileController]);