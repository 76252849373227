/***
 * Manages Main Menu creation
 * Allow the creation of a dynamic menu by app/modules
 * Only one level of nesting is allowed
 */
base.factory('Menu', function() {

    /***
     * Contains the registered menu items
     * @type {Array}
     */
    var menu = [];

    /***
     * Clear the current menu
     */
    function clear() {
        menu.splice(0, menu.length);
    }

    /***
     * Return the built menu
     * @returns {Array}
     */
    function getMenu() {
        return menu;
    }

    /***
     * Register a new menu entry
     * @param label Label of menu item
     * @param icon Icon of menu item
     * @param visible Visibility of item
     * @param link Link (optional)
     * @returns {{label: *, icon: *}} return the instance of menu item
     */
    function registerMenuEntry(label, icon, visible, link) {
        var item = {label: label, icon: icon, visible: visible};

        // Check for optional param
        if (link) { item.link = link; }

        menu.push(item);

        return item;
    }

    /***
     * Register a submenu entry
     * @param parent Menu item that will contain the submenu entry
     * @param label Label of the submenu entry
     * @param icon Icon of the submenu entry
     * @param visible Visibility of submenu entry
     * @param link Link of the submenu entry (optional)
     * @returns {registerSubMenuEntry} Return the service instance for cascading registers
     */
    function registerSubMenuEntry(parent, label, icon, visible, link) {
        var item = { label: label, icon: icon, visible: visible};

        // Check for optional param
        if(link) { item.link = link; }

        if(!parent.submenu) { parent.submenu = []; }

        parent.submenu.push(item);

        return this;
    }

    return {
        clear: clear,
        getMenu: getMenu,
        registerMenuEntry: registerMenuEntry,
        registerSubMenuEntry: registerSubMenuEntry
    };

});