base.directive('ssTable', [function () {
    return {
        restrict: 'E',
        transclude: true,
        templateUrl: 'app/modules/base/views/directives/ssTableBody.html',
        scope: {
            params: '='
        },
        controller: function($scope, LoadErrorDialog, $q, $mdDialog, SimpleDialog, Loader, GridFilters, $location) {
            for(var i in $scope.params.columns) {
                if($scope.params.columns[i].order === undefined) {
                    $scope.params.columns[i]["order"] = true;
                }
                if($scope.params.columns[i].label === undefined) {
                    $scope.params.columns[i]["label"] = "";
                }
                if($scope.params.columns[i].search === undefined) {
                    $scope.params.columns[i]["search"] = true;
                }
                if($scope.params.columns[i].searchType === undefined) {
                    $scope.params.columns[i]["searchType"] = 'text';
                }
                if($scope.params.columns[i].options !== undefined && $scope.params.columns[i].options.actions === undefined) {
                    $scope.params.columns[i]["actions"] = [];
                }
            }

            if($scope.params.enableSearch === undefined) {
                $scope.params.enableSearch = true;
            }

            if($scope.params.items === undefined) {
                $scope.params.items = [];
            }

            if($scope.params.initialOrder === undefined) {
                $scope.params.initialOrder = '-id';
            }

            $scope.$watch(function () {
                return $scope.params.reloadTable;
            }, function (value) {
                if(value === true) {
                    getData(paginationSettings.limit, paginationSettings.page, paginationSettings.order);
                    $scope.params.reloadTable = false;
                }
            }, true);

            function getData(limit, page, order) {
                $scope.order = order;
                $scope.page = page;
                $scope.limit = limit;

                GridFilters.setPaginationSettings({page: page, limit: limit, order: order});
                GridFilters.setFilterSettings({showClearFilter: $scope.showClearFilter, searchFields: $scope.searchFields});

                var promise = $scope.params.service.getAll(page, limit, order, prepareFilter());
                promise.then(onDataLoad);
                promise.catch(onDataLoadError);

                $scope.promise = promise;
            }

            function onDataLoad(data) {
                $scope.items = {
                    data: data.data,
                    count: data.total,
                    page: data.currentPage,
                    limit: data.limit
                };

                $scope.params.items = data.data;
            }

            function onDataLoadError() {
                LoadErrorDialog.show('/');
            }

            function prepareFilter(){
                var filter = '';

                for(var index in $scope.searchFields){
                    if($scope.searchFields[index]){
                        if(filter){
                            filter += "&";
                        }

                        filter += index+"="+$scope.searchFields[index];
                    }
                }

                if(filter == ''){
                    $scope.showClearFilter = false;
                }

                return filter;
            }

            function onReorder(order) {
                getData($scope.items.limit, $scope.items.page, order);
            }

            function search(){
                $scope.showClearFilter = true;

                getData($scope.items.limit, $scope.items.page, $scope.order);
            }

            function onPaginate() {
                getData($scope.items.limit, $scope.items.page, $scope.order);
            }

            function clearFilter(){
                for(var index in $scope.searchFields){
                    $scope.searchFields[index] = null;
                }

                $scope.searchText = null;
                $scope.showClearFilter = false;

                getData($scope.items.limit, $scope.items.page, $scope.order);
            }

            function actionEvent(isRoute, click, item)
            {
                if(isRoute) {
                    url(isRoute + item.id);
                } else {
                    click(item);
                }
            }

            function url (url, clearGridFilters) {
                if (clearGridFilters) {
                    GridFilters.deleteAll();
                }

                $location.path(url);
                $scope.sideBarVisible = false;
            }

            const paginationSettings = GridFilters.getPaginationSettings({page: 1, limit: 10, order: $scope.params.initialOrder});
            const filterSettings = GridFilters.getFilterSettings({showClearFilter: false,
                searchFields: {
                    id : null,
                    description: null
                }
            });

            $scope.options = {
                rowSelection: $scope.params.hasCheckbox,
                multiSelect: $scope.params.hasCheckbox
            };

            $scope.showClearFilter = filterSettings.showClearFilter;
            $scope.searchFields = filterSettings.searchFields;

            $scope.selected = [];
            $scope.paginate = onPaginate;
            $scope.reorder = onReorder;
            $scope.search = search;
            $scope.clearFilter = clearFilter;
            $scope.actionEvent = actionEvent;

            getData(paginationSettings.limit, paginationSettings.page, paginationSettings.order);
        }
    };
}]);