function StoreModel()
{
    this.id = null;
    this.name = '';
    this.tradeName = '';
    this.documentNumber = '';
    this.description = '';
    this.site = '';
    this.phone = '';
    this.email = '';
    this.reference = null;
    this.area = null;
    this.brand = null;
    this.floor = '';
    this.lucSuc = null;
    this.logo = '';
    this.coverImage = null;
    this.segments = [];
    this.address = {
        "zipCode": "",
        "streetName": "",
        "complement": "",
        "neighborhood": "",
        "number": "",
        "city": "",
        "state": "",
        "country": ""
    };
    this.showStore = true;
    this.isActive = true;
}

StoreModel.prototype.fromService = function(data) {
    this.id = data.id;
    this.name = data.name;
    this.tradeName = data.tradeName;
    this.documentNumber = data.documentNumber;
    this.description = data.description;
    this.site = data.site;
    this.phone = data.phone;
    this.email = data.email;
    this.reference = data.reference;
    this.area = data.area;
    this.brand = data.brand;
    this.floor = data.floor;
    this.lucSuc = data.lucSuc;
    this.logo = data.logo;
    this.coverImage = data.coverImage;
    this.segments = data.segments;
    
    if (data.address) {
        this.address = {
            "zipCode": data.address.zipCode,
            "streetName": data.address.streetName,
            "complement": data.address.complement,
            "neighborhood": data.address.neighborhood,
            "number": data.address.number,
            "city": data.address.city,
            "state": data.address.stateCode,
            "country": data.address.country
        };
    }
    this.showStore = data.showStore;
    this.isActive = true;
};
