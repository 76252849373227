function ListOrTree($mdDialog) {
    return {
        restrict: 'E',
        templateUrl: 'app/modules/base/views/directives/listOrTree.html?version=2',
        transclude: true,
        scope: {
            label : '@',
            inputName : '@',
            showList: '@',
            showTree: '@',
            multiCheck: '@',
            returnObjects: '@',
            closeOnSelect: '=',
            onSelect: '=',
            checkParents: '=',
            list: '=',
            tree: '=',
            ngModel: '='
        },
        link: function ($scope, $element, $attributes, ngModel) {
            if($scope.label === undefined || $scope.label === '') {
                $scope.label = 'Selecione o segmento';
            }

            if($scope.inputName === undefined || $scope.inputName === '') {
                $scope.inputName = 'segments';
            }

            $scope.isRequired = ($attributes.required === '' || $attributes.required === 'true');
            $scope.showTree = (!$scope.showTree || $scope.showTree === 'true');
            $scope.showList = (!$scope.showList || $scope.showList === 'true');
            $scope.multiCheck = (!$scope.multiCheck || $scope.multiCheck !== 'false');
            $scope.returnObjects = (!$scope.returnObjects || $scope.returnObjects === 'true');
            $scope.checkParents = ($scope.checkParents || $scope.checkParents === 'true');
            $scope.selected = [];

            $scope.$watch(function() {
                return $scope.ngModel;
            }, function() {
                $scope.selected = [];

                if(angular.isArray($scope.ngModel) && $scope.ngModel.length > 0 && $scope.multiCheck){
                    for(var i in $scope.ngModel) {
                        if($scope.returnObjects && angular.isObject($scope.ngModel[i]) && $scope.ngModel[i].id !== undefined){
                            $scope.selected.push(parseInt($scope.ngModel[i].id));
                        }else{
                            $scope.selected.push(parseInt($scope.ngModel[i]));
                        }
                    }
                }else if(!$scope.multiCheck){
                    if($scope.returnObjects && angular.isObject($scope.ngModel) && $scope.ngModel.id !== undefined){
                        $scope.selected.push(parseInt($scope.ngModel.id));
                    }else{
                        $scope.selected.push(parseInt($scope.ngModel));
                    }
                }
            });

            $scope.openModal = openModal;

            function openModal() {
                function ModalController($scope, $mdDialog, list, tree, selected, label, multiCheck, checkParents) {
                    $scope.selected = selected;
                    $scope.loading = true;
                    $scope.showTree = false;
                    $scope.treeSegments = [];
                    $scope.segments = [];

                    $scope.listActive = false;
                    $scope.treeActive = false;
                    $scope.label = label;
                    $scope.multiCheck = multiCheck !== 'false';
                    $scope.checkParents = checkParents;

                    list.active = list.active!=='false';
                    tree.active = tree.active!=='false';

                    if(list.active && isFunction(list.func)) {
                        list.func().then(function(data){
                            $scope.segments = data;
                            $scope.loading = false;
                        });

                        $scope.listActive = true;
                    }else if(tree.active && isFunction(tree.func)){
                        $scope.treeActive = true;

                        if(!$scope.treeSegments.length){
                            tree.func().then(function (segments) {
                                $scope.treeSegments = segments;
                                $scope.showTree = true;
                                $scope.loading = false;
                            });
                        }else{
                            $scope.showTree = true;
                            $scope.loading = false;
                        }
                    }

                    if(tree.active && isFunction(tree.func)) {
                        $scope.treeActive = true;
                    }

                    $scope.addSegment = function(segment) {
                        if(!$scope.multiCheck){
                            $scope.selected = [segment];
                        }else{
                            if($scope.selected.indexOf(segment) > -1){
                                var index = $scope.selected.indexOf(segment);
                                $scope.selected.splice(index, 1);
                            }else{
                                $scope.selected.push(segment);
                                markParent(segment, false);
                            }
                        }
                    };

                    function markParent(segment, parent) {
                        if($scope.checkParents) {
                            for(var i in $scope.segments) {
                                if($scope.segments[i].id === segment) {
                                    if(parent) {
                                        $scope.segments[i]['parentMarked'] = parent;
                                    }else {
                                        $scope.segments[i]['parentMarked'] = false;
                                    }

                                    if($scope.segments[i].parentId) {
                                        $scope.selected.push($scope.segments[i].parentId);
                                        markParent($scope.segments[i].parentId, $scope.segments[i].name);
                                    }
                                }
                            }
                        }
                    }

                    $scope.showTreeAction = function(){
                        if(!$scope.showTree && $scope.treeActive){
                            $scope.loading = true;
                            $scope.showTree = true;

                            if(!$scope.treeSegments.length){
                                tree.func().then(function (segments) {
                                    $scope.treeSegments = segments;
                                    $scope.loading = false;
                                });
                            }else{
                                $scope.loading = false;
                            }
                        }else{
                            $scope.showTree = false;
                        }
                    };

                    $scope.close = function () {
                        $mdDialog.cancel({
                            segments: $scope.segments,
                            value: $scope.selected
                        });
                    };

                    $scope.select = function () {
                        $mdDialog.hide({
                            segments: $scope.segments,
                            value: $scope.selected
                        });
                    };

                    $scope.$on('ngRepeatSegments', function(ngRepeatSegmentsFinishedEvent) {
                        $scope.loading = false;
                    });

                    function isFunction(functionToCheck) {
                        var getType = {};
                        return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
                    }
                }

                var locals = {
                    list: {
                        func : $scope.list,
                        active: $scope.showList
                    },
                    tree: {
                        func : $scope.tree,
                        active: $scope.showTree
                    },
                    selected: $scope.selected,
                    label: $scope.label,
                    multiCheck: $scope.multiCheck,
                    checkParents: $scope.checkParents
                };

                var dialog = {
                    clickOutsideToClose: true,
                    controller: ModalController,
                    locals: locals,
                    preserveScope: true,
                    templateUrl: 'app/modules/base/views/directives/listOrTreeModal.html?version=2'
                };

                $mdDialog.show(dialog).then(function(value) {
                    if(angular.isArray($scope.ngModel)){
                        $scope.ngModel = [];
                    }

                    for(var s in value.value){
                        for(var segmentIndex in value.segments){
                            if(value.value[s] === value.segments[segmentIndex].id) {
                                if($scope.returnObjects !== 'false'){
                                    if($scope.multiCheck !== 'false'){
                                        $scope.ngModel.push(value.segments[segmentIndex]);
                                    }else{
                                        $scope.ngModel = value.segments[segmentIndex];
                                    }
                                }else{
                                    if($scope.multiCheck !== 'false'){
                                        $scope.ngModel.push(parseInt(value.value[s]));
                                    }else{
                                        $scope.ngModel = parseInt(value.value[s]);
                                    }
                                }
                            }
                        }
                    }
                },function() {
                    if(!$scope.ngModel.length) {
                        $scope.selected = [];
                    }
                });
            }
        }
    }
}

base.directive('listOrTree', ListOrTree);