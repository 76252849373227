function KnowledgeBaseListController($scope, $route, SimpleDialog, GridFilters, KnowledgeBaseService)
{
    onInit();

    function onInit()
    {
        initScope();
        reload();
    }

    function initScope()
    {
        $scope.filter = GridFilters.getFilterSettings({hasFilter: false, searchFields: {}});
        $scope.pagination = GridFilters.getPaginationSettings({limit: 10, page: 1, order: '-title'});
        $scope.reload = reload;
        $scope.clear = clear;
    }

    function onDataLoad(items)
    {
        $scope.pagination.limit = items.limit;
        $scope.pagination.page = items.currentPage;
        $scope.items = items;
    }

    function onDataLoadError()
    {
        SimpleDialog.show('Base de conhecimento', 'Não foi possível carregar os items no momento', 'Tentar novamente')
            .then($route.reload);
    }

    function reload()
    {
        $scope.filter.hasFilter = filterNotEmpty();

        GridFilters.setPaginationSettings($scope.pagination);
        GridFilters.setFilterSettings($scope.filter);

        $scope.promise = KnowledgeBaseService.getAll($scope.pagination, $scope.filter.searchFields)
            .then(onDataLoad)
            .catch(onDataLoadError);
    }

    function filterNotEmpty()
    {
        const keys = Object.keys($scope.filter.searchFields);
        return keys.filter(function(key) { return notNullOrEmpty($scope.filter.searchFields[key]) }).length > 0;
    }

    function notNullOrEmpty(value)
    {
        return value !== undefined && value !== null && value !== '';
    }

    function clear()
    {
        Object.keys($scope.filter.searchFields).map(function(key) { $scope.filter.searchFields[key] = null });
    }
}

knowledgeBase.controller('KnowledgeBaseListController',
    ['$scope', '$route', 'SimpleDialog', 'GridFilters', 'KnowledgeBaseService', KnowledgeBaseListController]);