/***
 * Page Information Service
 * Store current page information such as Tab Title and Page Header
 */
base.factory('PageInformation', ['State', function(State) {

    // Keys for state storage using internal prefix
    var PAGE_HEADER_KEY = '_@pageHeader';
    var PAGE_TITLE_KEY = '_@pageTitle';
    var PAGE_ROUTE_KEY = '_@pageRoute';

    /***
     * Get the current page header
     * @returns {string}
     */
    function getPageHeader() {
        return State.getValue(PAGE_HEADER_KEY);
    }

    /***
     * Get the current page title
     * @returns {string}
     */
    function getPageTitle() {
        return State.getValue(PAGE_TITLE_KEY);
    }

    /***
     * Set the text that goes bellow main menu
     * @param value
     */
    function setPageHeader(value) {
        State.setValue(PAGE_HEADER_KEY, value);
    }

    /***
     * Set the text that appears on browser tab
     * @param value
     */
    function setPageTitle(value) {
        State.setValue(PAGE_TITLE_KEY, value);
    }

    function getCurrentRoute()
    {
        return State.getValue(PAGE_ROUTE_KEY);
    }

    function setCurrentRoute(value) {
        State.setValue(PAGE_ROUTE_KEY, value);
    }

    // Init with default values
    setPageTitle('');
    setPageHeader('');
    setCurrentRoute('');

    return {
        getCurrentRoute: getCurrentRoute,
        getPageHeader: getPageHeader,
        getPageTitle: getPageTitle,
        setCurrentRoute: setCurrentRoute,
        setPageHeader: setPageHeader,
        setPageTitle: setPageTitle
    };

}]);