function ServiceRequestPersonController($scope, $mdDialog, Loader, SimpleDialog, $timeout, $q, ServiceRequestService) {
    $scope.person = new PersonServiceRequestModel();
    $scope.loading = false;

    $scope.close = function () {
        $mdDialog.hide();
    };

    $scope.addPerson = function () {
        $mdDialog.hide($scope.person);
    };

    $scope.searchPerson = function() {
        var documentType = $scope.person.documentType;
        var document = $scope.person.document;
        var dateOfBirth = $scope.person.dateOfBirth;

        if (documentType === 'cpf' && document && document.length === 11 && dateOfBirth && dateOfBirth.length === 10) {
            $scope.loading = true;

            ServiceRequestService.getPersonPromise(document, dateOfBirth, Loader.hide).then(function(result) {
                if (result.status != 200) {
                    throw (result.status);
                }

                $scope.person.name = result.data.data.name;

            }).finally(function () {
                $scope.loading = false;
            });
        }
    };
}

serviceRequest.controller('ServiceRequestPersonController', ServiceRequestPersonController);
