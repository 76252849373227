'use strict';

shop.factory('Shop', ['$http', 'Middleware', function ($http, Middleware) {

    var services = {
        shop: Middleware.getUrl('store')
    };

    return {
        getShop: function () {
            return $http.get(services.shop + '/get').then(function (response) {
                var store = new StoreModel();
                store.fromService(response.data.data);
                return store;
            });
        },

        save: function (shop) {
            var data = JSON.stringify(shop);

            return $http.post(services.shop, data).then(function (response) {
                return response;
            });
        }
    };
}]);