earning.factory('EarningService', ['$http', 'Middleware', function ($http, Middleware) {

    var services = {
        earning: Middleware.getUrl('earning/list/'),
        conf: Middleware.getUrl('earning/get-actual-config'),
        getOpen: Middleware.getUrl('earning/get-open'),
        save: Middleware.getUrl('earning/')
    };
    
    function save(earning)
    {
        var json = JSON.stringify(earning);

        return $http.post(services.save, json).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getAll(limit, page, order, filter)
    {
        var url = services.earning + limit + "/" + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getOpen()
    {
        return $http.get(services.getOpen).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getById(id)
    {
        return $http.get(services.earning +"get/" +id).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getConf()
    {
        return $http.get(services.conf).then(function(result) {
            return result.data.data;
        });
    }

    return {
        save: save,
        getAll: getAll,
        getById: getById,
        getConf: getConf,
        getOpen: getOpen
    };

}]);