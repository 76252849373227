function LoadErrorDialog($location, $route, $mdDialog) {
    function show(backPath) {
        function onConfirm() {
            $route.reload();
        }

        function onCancel() {
            $location.path(backPath);
        }

        var confirm = $mdDialog.confirm()
            .title('Ops')
            .textContent('Não foi possível carregar as informações. Deseja tentar novamente?')
            .ariaLabel('Erro ao carregar informações')
            .ok('ok')
            .cancel('Cancelar');

        var promise = $mdDialog.show(confirm);
        promise.then(onConfirm);
        promise.catch(onCancel);
    }

    return {
        show: show
    };
}

base.factory('LoadErrorDialog', ['$location', '$route', '$mdDialog', LoadErrorDialog]);