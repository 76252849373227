/***
 * Manages routing of application
 */
function RouteManager($rootScope, $route, User)
{
    /***
     * StoreModel a route register callback which is
     * called every time user privileges changes
     * @type {Array}
     */
    var routeProviders = [];

    /***
     * Angular Service for providing routes
     */
    var routeProvider;

    /***
     * Remove all application routes
     */
    function clearAll() {
        // Get all route names
        var keys = Object.keys($route.routes);

        var size = keys.length;
        for(var i = 0; i < size; i++) {
            // Delete each registered route
            delete $route.routes[keys[i]];
        }
    }

    /***
     * Call all route provider callbacks
     */
    function provideRoutes() {
        var size = routeProviders.length;
        for(var i = 0; i < size; i++) {
            // Use the provider callback
            routeProviders[i](app.routeProvider, User);
        }
    }

    /***
     * Register a route provider
     * @param providerCallback Callback function which register module specific routes
     */
    function registerProvider(providerCallback) {
        routeProviders.push(providerCallback);
        providerCallback(app.routeProvider, User)
    }

    /***
     * Set angular route provider that will passed to callbacks
     */
    function setRouteProviderService(provider) {
        routeProvider = provider;
    }

    return {
        clearAll: clearAll,
        provideRoutes: provideRoutes,
        registerProvider: registerProvider,
        setRouteProviderService: setRouteProviderService
    };
}

base.factory('RouteManager', ['$rootScope', '$route', 'User', RouteManager]);