function JobCandidates($http, Middleware)
{
    const services = {
        Candidate: Middleware.getUrl('candidates/')
    };

    function getAll(limit, page, order, filter) {
        var url = services.Candidate + "list/" + limit + '/' + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function getById(id) {
        return $http.get(services.Candidate + 'get/' + id, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    return {
        getAll: getAll,
        getById: getById
    };
}

jobs.factory('JobCandidates', ['$http', 'Middleware', JobCandidates]);
