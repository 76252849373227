function UserModel() {
    this.id = null;
    this.documentNumber = "";
    this.userName = "";
    this.oldPassword = null;
    this.newPassword = null;
    this.name = "";
    this.otherDocument = "";
    this.birthDate = "";
    this.maritalStatus = "";
    this.gender = "";
    this.occupation = "";
    this.coverImage = null;
    this.address = {
        "zipCode": "",
        "streetName": "",
        "complement": "",
        "neighborhood": "",
        "number": "",
        "city": "",
        "state": "",
        "country": ""
    };

    this.email = "";
    this.site = "";
    this.environmentProfile = "";
    this.environmentProfileName = "";
    this.storeId = "";
    this.accessEnvironments =[];

    this.phone = {id: null, phone: null, type: "", isDefault: false};
    this.celPhone = {id: null, phone: null, type: "", isDefault: true};

    this.phones = [];
}

UserModel.prototype.fromService = function (data) {
    if (data.phones[0] != undefined) {
        this.phone = {id: data.phones[0].id, phone: data.phones[0].phone, type: data.phones[0].type, isDefault: data.phones[0].isDefault};
    }

    if (data.phones[1] != undefined) {
        this.celPhone = {id: data.phones[1].id, phone: data.phones[1].phone, type: data.phones[1].type, isDefault: data.phones[1].isDefault};
    }

    this.id = data.id;
    this.documentNumber = data.documentNumber;
    this.userName = data.userName;
    this.name = data.name;
    this.otherDocument = data.otherDocument;

    this.setBirthDate(data.birthDate);

    this.maritalStatus = data.maritalStatus;
    this.gender = data.gender;
    this.occupation = data.occupation;

    if (data.address) {
        this.address = {
            "zipCode": data.address.zipCode,
            "streetName": data.address.streetName,
            "complement": data.address.complement,
            "neighborhood": data.address.neighborhood,
            "number": parseInt(data.address.number),
            "city": data.address.city,
            "state": data.address.stateCode,
            "country": data.address.country
        };
    }

    this.email = data.email;
    this.site = data.site;
    this.environmentProfile = data.environmentProfile;
    this.environmentProfileName = data.environmentProfileName;
    this.storeId = "";
    this.coverImage = data.picture;
    this.accessEnvironments = data.accessEnvironments;
};

UserModel.prototype.setBirthDate = function (date) {
    if(date){
        this.birthDate = moment(date.date).format('DD/MM/YYYY');
    }
};