function JobsListCandidatesController($scope, LoadErrorDialog, JobCandidates, JobOpportunity, SimpleDialog, $mdDialog, Loader, $q, $mdpDatePicker) {
    Loader.show();

    $scope.selected = [];

    function onPaginate() {
        loadData($scope.items.limit, $scope.items.page, $scope.order);
    }

    function onDataLoad(candidate) {
        $scope.items = {
            data: candidate.data,
            count: candidate.total,
            page: candidate.currentPage,
            limit: candidate.limit
        };
    }

    function onDataLoadError(error) {
        if (error) LoadErrorDialog.show('/');
    }

    function loadData(limit, page, order) {
        var promise = JobCandidates.getAll(limit, page, order, prepareFilter());
        promise.then(onDataLoad);
        promise.catch(onDataLoadError);
        promise.finally(function () {
            Loader.hide();
        });

        $scope.promise = promise;
    }

    function onDelete(event) {
        if ($scope.selected.length == 0) {
            showDeleteError(event);
            return;
        }

        showDeleteConfirm(event);
    }

    function deleteSelected(event) {
        var selected = $scope.selected;
        var size = selected.length;

        Loader.show();

        var promises = [];

        for (var i = 0; i < size; i++) {
            var item = selected[i];
            promises.push(Candidate.remove(item.id));
        }

        var promise = $q.all(promises);

        promise.then(function () {
            SimpleDialog.show('Sucesso', 'Registro(s) removido(s) com sucesso.', 'Ok', event);
        });

        promise.catch(function () {
            SimpleDialog.show('Ops', 'Um ou mais registros não foram removidos.', 'Ok', event);
        });

        promise.finally(function () {
            loadData($scope.items.limit, $scope.items.page, $scope.order);
        });

        $scope.selected = [];
    }

    function showDeleteConfirm(event) {
        var confirm = $mdDialog.confirm()
            .title('Remover registros')
            .textContent('Tem certeza que deseja remover os registro(s) selecionado(s)?')
            .ariaLabel('Remover registros')
            .targetEvent(event)
            .ok('Remover')
            .cancel('Cancelar');

        var promise = $mdDialog.show(confirm);

        promise.then(function () {
            deleteSelected(event);
        });
    }

    function showDeleteError(event) {
        SimpleDialog.show('Nenhum registro selecionado', 'Selecione pelo menos um registro para remoção.', 'Ok', event);
    }

    $scope.viewSelected = function ($event, id) {
        Loader.show();

        JobCandidates.getById(id).then(function(currentCandidate){

            $mdDialog.show({
                templateUrl: 'app/modules/jobs/views/candidate-details.html',
                parent: angular.element(document.body),
                clickOutsideToClose: true,
                targetEvent: $event,
                fullscreen: false,
                controller: function ($scope, $mdDialog, candidate) {
                    $scope.candidate = candidate;

                    $scope.close = function () {
                        $mdDialog.hide();
                    };

                    Loader.hide();
                },
                locals: {
                    candidate: currentCandidate
                }
            });

        })
    };

    $scope.showDatePicker = function (ev) {
        $mdpDatePicker($scope.searchDate, {
            targetEvent: ev
        }).then(function (selectedDate) {
            $scope.searchDate = selectedDate;
            $scope.searchFields.createdDateTime = moment(selectedDate).format('DD-MM-YYYY');
            search();
        });
    };

    $scope.changeFilterOpportunityType = function(param) {
        if($scope.items){
            for(var index in $scope.searchFields){
                $scope.searchFields[index] = null;
            }

            $scope.searchFields.selectedType = param;

            loadData($scope.items.limit, $scope.items.page, $scope.order);
        }
    };

    $scope.changeFilterOpportunity = function(param) {
        if($scope.items){
            for(var index in $scope.searchFields){
                if(index != 'selectedType'){
                    $scope.searchFields[index] = null;
                }
            }

            $scope.searchFields.opportunityId = param;
            $scope.showClearFilter = true;

            loadData($scope.items.limit, $scope.items.page, $scope.order);
        }
    };

    function loadOpportunities() {
        return JobOpportunity.getAll(5000, 1, '-id').then(function(response){
            $scope.opportunities = response.data;
            $scope.opportunities.unshift({'id': '', 'name': 'Todas'});
        });
    }

    function prepareFilter(){
        var filter = '';

        for(var index in $scope.searchFields){
            if($scope.searchFields[index] !== null && $scope.searchFields[index] !==''){
                if(filter){
                    filter += "&";
                }

                filter += index+"="+$scope.searchFields[index];
            }
        }

        if(filter == ''){
            $scope.showClearFilter = false;
        }

        return filter;
    }

    function search(){
        $scope.showClearFilter = true;

        loadData($scope.items.limit, $scope.items.page, $scope.order);
    }

    function clearFilter(){
        for(var index in $scope.searchFields){
            if(index != 'selectedType'){
                $scope.searchFields[index] = null;
            }
        }

        $scope.searchText = null;
        $scope.showClearFilter = false;
        $scope.searchDate = null;
        $scope.selectedShopkeeper = '';
        $scope.selectedArea = '';
        $scope.selectedOpportunity = '';

        loadData($scope.items.limit, $scope.items.page, $scope.order);
    }

    function onReorder(order) {
        loadData($scope.items.limit, $scope.items.page, order);
    }

    $scope.clearSegmentSearch = '';
    $scope.searchFields = {
        id: null,
        name: null,
        email: null,
        createdDateTime: null,
        opportunityId: null,
        areaId: null,
        selectedType: 'store'
    };

    $scope.showClearFilter = false;
    $scope.order = '-id';

    // $scope.loadAreas = loadAreas();
    $scope.loadOpportunities = loadOpportunities();

    $scope.paginate = onPaginate;
    $scope.onDelete = onDelete;
    $scope.search = search;
    $scope.clearFilter = clearFilter;
    $scope.reorder = onReorder;

    loadData(10, 1, $scope.order);
}

jobs.controller('JobsListCandidatesController', JobsListCandidatesController);
