base.factory('Table', [function() {

    var Table = function() {
        this.data = null;
        this.promise = null;
        this.selected = [];

        this.options = {
            autoSelect: true,
            boundaryLinks: true,
            largeEditDialog: false,
            pageSelector: true,
            rowSelect: true
        };

        this.filter = {};
        this.query = {
            order: '',
            limit: 10,
            page: 1
        }

        this.events = {
            mdOnPaginate: this.clearSelection,
            mdOnReorder: this.clearSelection,
            mdOnSelect: null,
            mdOnDeselect: null
        }
    }

    Table.prototype.clearSelection = function() {
        this.selected = [];
    }

    function createTable()
    {
        return new Table();
    }

    return {
        createTable: createTable
    };

}]);