function GridFilters(Breadcrumb)
{
    const paginations = {};
    const filters = {};

    var currentName = '';

    function contains(page)
    {
        return filters.hasOwnProperty(page);
    }

    function containsCurrentPage()
    {
        return contains(getCurrentPage());
    }

    function getCurrentPage()
    {
        return currentName;
    }

    function getPaginationSettings(defaultTo)
    {
        defaultTo = defaultTo ? defaultTo : null;
        return containsCurrentPage() ? paginations[getCurrentPage()] : defaultTo;
    }

    function getFilterSettings(defaultTo)
    {
        defaultTo = defaultTo ? defaultTo : null;
        return containsCurrentPage() ? filters[getCurrentPage()] : defaultTo;
    }

    function setPaginationSettings(settings)
    {
        paginations[getCurrentPage()] = settings;
    }

    function setFilterSettings(settings)
    {
        filters[getCurrentPage()] = settings;
    }

    function deleteUnused()
    {
        const keys = Object.keys(filters);
        for (var index in keys) {
            var key = index = keys[index];
            if (!Breadcrumb.isOnCurrentBreadcrumb(key)) {
                delete filters[key];
                delete paginations[key];
            }
        }
    }

    function deleteAll()
    {
        const keys = Object.keys(filters);
        for (var index in keys) {
            var key = index = keys[index];
            delete filters[key];
            delete paginations[key];
        }
    }

    function setCurrentRouteName(name)
    {
        currentName = name;
    }

    return {
        getPaginationSettings: getPaginationSettings,
        getFilterSettings: getFilterSettings,
        setPaginationSettings: setPaginationSettings,
        setFilterSettings: setFilterSettings,
        setCurrentRouteName: setCurrentRouteName,
        deleteUnused: deleteUnused,
        deleteAll: deleteAll
    };
}

base.factory('GridFilters', ['Breadcrumb', GridFilters]);