'use strict';

base.directive('ssImageOverlay',  ['$compile', '$timeout', function($compile, $timeout) {

    return {
        restrict: 'A',
        scope: true,
        link: function($scope, $element, $attr) {

            $scope.overlay = null;

            $scope.close = function() {
                if($scope.overlay) {
                    $scope.overlay.removeClass('fade-in');
                    $timeout(function() {
                        $scope.overlay.remove();
                        $scope.overlay = null;
                    }, 1000);
                }
            }

            $element.bind('click', function() {
                var body = angular.element(document.body);

                var src = $element.attr('src');

                var template =
                    '<div class="ss-image-overlay-container" layout="row" layout-align="center center" ng-click="close()">' +
                        '<img src="' + src + '">' +
                    '</div>';

                $scope.overlay = $compile(template)($scope);

                body.append($scope.overlay);

                $timeout(function() {
                    $scope.overlay.addClass('fade-in');
                }, 100);
            });

        },

    }

}]);
