profile.factory('MyAccount', ['$http', 'Middleware', function($http, Middleware) {

    var services = {
        profile: Middleware.getUrl('users/')
    };

    function getProfile(id) {
        return $http.get(services.profile + 'get/' + id).then(function (response) {
            var mapper = new UserModel();
            mapper.fromService(response.data.data);
            return mapper;
        });
    }

    function edit(id, user) {

        return $http.post(services.profile + id, user).then(function (response) {
            return response;
        });
    }

    return {
        getProfile: getProfile,
        edit: edit
    };

}]);