function SimpleDialog($mdDialog) {
    function show(title, content, button, event) {
        var confirm = $mdDialog.alert()
            .title(title)
            .textContent(content)
            .ariaLabel(title)
            .ok(button);

        if (event) {
            confirm.targetEvent(event);
        }

        return $mdDialog.show(confirm);
    }

    return {
        show: show
    };
}

base.factory('SimpleDialog', ['$mdDialog', SimpleDialog]);