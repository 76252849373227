employee.factory('Employees', ['$http', 'Middleware', function($http, Middleware) {
    const services = {
        users: Middleware.getUrl('users/')
    };

    function create(users) {
        var data = JSON.stringify(users);

        return $http.post(services.users, data).then(function (response) {
            return response;
        });
    }

    function edit(id, users) {
        var data = JSON.stringify(users);

        return $http.post(services.users + id, data).then(function (response) {
            return response;
        });
    }

    function getAllOnEnvironment(limit, page, order, filter) {
        var url = services.users + 'list-on-environment/' + limit + '/' + page + '?order='+ order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function getById(id) {
        return $http.get(services.users + 'get/' + id).then(function (response) {
            var users = new EmployeeModel();
            users.fromService(response.data.data);
            return users;
        });
    }

    function getByDocument(document) {
        return $http.get(services.users + 'get-by-document/' + document).then(function (response) {
            var users = new EmployeeModel();
            users.fromService(response.data.data);
            return users;
        });
    }

    function remove(id, store) {
        if (store) {
            return $http.delete(services.users + id + "/" + store);
        }

        return $http.delete(services.users + id);
    }

    function emailExists(email) {
        return $http.get(services.users + 'check-email?email=' + email, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function userNameExists(username) {
        return $http.get(services.users + 'check-user-name?userName=' + username, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    return {
        create: create,
        edit: edit,
        getAllOnEnvironment: getAllOnEnvironment,
        getById: getById,
        getByDocument: getByDocument,
        remove: remove,
        emailExists: emailExists,
        userNameExists: userNameExists
    };
}]);