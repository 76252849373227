base.factory('GeneralConf', ['$http', 'Middleware', function ($http, Middleware) {
    var services = {
        conf: Middleware.getUrl('shopkeeper-conf/')
    };

    function getConf()
    {
        return $http.get(services.conf).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    return {
        getConf: getConf
    };

}]);