function KnowledgeBaseViewController($scope, $location, $routeParams, SimpleDialog, Loader, KnowledgeBaseService)
{
    onInit();

    function onInit()
    {
        initScope($routeParams.id);
    }

    function initScope(id)
    {
        Loader.show();
        KnowledgeBaseService.getByIdentifier(id)
            .then(onItemLoad)
            .catch(onItemLoadError)
            .finally(Loader.hide);
    }

    function onItemLoad(item)
    {
        $scope.item = item;
    }

    function onItemLoadError()
    {
        SimpleDialog.show('Base de conhecimento', 'Não foi possível carregar a página no momento.', 'Ok')
            .then(function() { $location.url('/base-conhecimento') });
    }
}

knowledgeBase.controller('KnowledgeBaseViewController',
    ['$scope', '$location', '$routeParams', 'SimpleDialog', 'Loader', 'KnowledgeBaseService', KnowledgeBaseViewController]);