// Intercepts all requests and add a timeout to make it cancellable when route changes
function RequestInterceptor($q, PendingRequestsManager) {
    function request(config) {
        if (config && config.cancellable && !config.timeout) {
            config.defer = PendingRequestsManager.createTimeout();
            config.timeout = config.defer.promise;
        }
        return config;
    }

    function requestError(rejection) {
        if (rejection.config.defer) {
            PendingRequestsManager.freeTimeout(rejection.config.defer);
            if (rejection.status == -1 && rejection.config.defer.canceled) {
                return $q.reject(false);
            }
        }

        return $q.reject(rejection);
    }

    function response(response) {
        if (response.config.defer) {
            PendingRequestsManager.freeTimeout(response.config.defer);
        }

        return response;
    }

    function onResponseError(rejection) {
        if (rejection.config && rejection.config.defer) {
            PendingRequestsManager.freeTimeout(rejection.config.defer);
            if (rejection.status == -1 && rejection.config.defer.canceled) {
                return $q.reject(false);
            }
        }

        return $q.reject(rejection);
    }

    return {
        request: request,
        requestError: requestError,
        response: response,
        responseError: onResponseError
    };
}

base.factory('RequestInterceptor', ['$q', 'PendingRequestsManager', RequestInterceptor]);
