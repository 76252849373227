/**
 * Can be ignored if request has skipUnauthorizedInterceptorHandler flag on config
 */
function UnauthorizedInterceptorHandler($q, $location) {
    function onResponseError(rejection) {
        if (rejection.config.skipUnauthorizedInterceptorHandler || rejection.status != 401) {
            return $q.reject(rejection);
        }

        $location.path('/logout');

        return $q.reject(false);
    }

    return {
        responseError: onResponseError
    };
}

base.factory('UnauthorizedInterceptorHandler', ['$q', '$location', UnauthorizedInterceptorHandler]);
