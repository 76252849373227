'use strict';

base.directive('ssGallery', ['$timeout', function($timeout) {

    return {

        restrict: 'E',

        scope: {items: "=items", index: "=index", isOpen: "=isOpen"},

        templateUrl: 'app/modules/base/views/directives/ssGallery.html',

        link: function($scope, $element) {
            $scope.currentImage = $scope.items[$scope.index];

            $scope.itemsLength = $scope.items.length;

            $scope.$watch('index.value', function(index) {
                $scope.currentImage = $scope.items[index];
            });

            $scope.next = function($event) {
                $scope.index.value  = ++$scope.index.value % $scope.itemsLength;
                $event.preventDefault();
                $event.stopPropagation();
            }

            $scope.previous = function($event) {
                $scope.index.value = --$scope.index.value  > -1? $scope.index.value : ($scope.itemsLength - 1);
                $event.preventDefault();
                $event.stopPropagation();
            }

            $scope.close = function() {
                $scope.isOpen.value = false;
            }
        }
    }

}]);