function ServiceRequestModel()
{
    this.id = null;
    this.categoryId = null;
    this.workScheduleId = null;
    this.status = null;
    this.subject = null;
    this.description = null;
    this.orientation = null;
    this.expectedStartDateTime = null;
    this.expectedFinishDateTime = null;
    this.referenceDate = null;
    this.authorizationConfigId = null;
    this.attachments = [];
    this.authorizedPersons = [];
    this.authorizationStatus = 1;
    this.authorizationReplies = [];
}

ServiceRequestModel.prototype.toFormData = function () {
    var formData = new FormData();

    var skipKeys = ['toFormData', 'attachments', 'authorizedPersons'];

    for (var key in this) {
        if(skipKeys.indexOf(key) == -1) {
            appendTo(key, this[key], formData);
        }
    }

    var i = 0;
    for (var key in this.attachments) {
        var attachment = this.attachments[key];
        formData.append('attachments[' + i + '][name]', attachment.name);
        formData.append('attachments[' + i + '][description]', attachment.description);
        formData.append('attachments[' + i + '][file]', attachment.file);
        i++;
    }

    i = 0;
    for (key in this.authorizedPersons) {
        var authorizedPerson = this.authorizedPersons[key];
        formData.append('authorizedPersons[' + i + '][document]', authorizedPerson.document);
        formData.append('authorizedPersons[' + i + '][name]', authorizedPerson.name);
        formData.append('authorizedPersons[' + i + '][phone]', authorizedPerson.phone);
        formData.append('authorizedPersons[' + i + '][brand]', authorizedPerson.brand);
        formData.append('authorizedPersons[' + i + '][model]', authorizedPerson.model);
        formData.append('authorizedPersons[' + i + '][plate]', authorizedPerson.plate);
        formData.append('authorizedPersons[' + i + '][color]', authorizedPerson.color);
        formData.append('authorizedPersons[' + i + '][year]', authorizedPerson.year);
        i++;
    }

    function appendTo(key, value, formData) {
        if(value) {
            formData.append(key, value);
        }
    }

    return formData;
};

function ServiceRequestAttachmentModel() {
    this.name = '';
    this.description = '';
    this.url = null;
    this.createdDateTime = null;
    this.file = null;
}

function PersonServiceRequestModel() {
    this.document = '';
    this.name = '';
    this.phone = '';
    this.brand = '';
    this.model = '';
    this.plate = '';
    this.color = '';
    this.year = '';
}

PersonServiceRequestModel.prototype.setData = function (data) {
    this.document = data.document;
    this.name = data.name;
    this.phone = data.phone;
    this.brand = data.brand;
    this.model = data.model;
    this.plate = data.plate;
    this.color = data.color;
    this.year = data.year;
};

ServiceRequestModel.prototype.fromService = function (data) {
    this.id = data.id;
    this.categoryId = data.categoryId;
    this.workScheduleId = data.workScheduleId;
    this.status = data.status;
    this.subject = data.subject;
    this.description = data.description;
    this.orientation = data.orientation;
    this.referenceDate = data.referenceDate;
    this.authorizationConfigId = data.authorizationConfigId;
    this.attachments = data.attachments;
    this.authorizedPersons = data.authorizedPersons;
    this.authorizationReplies = data.authorizationReplies;
    this.authorizationStatus = data.authorizationStatus;
};