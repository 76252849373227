var PageTinyMCEConfig = {
    inline: false,
    skin: 'light',
    theme: 'modern',
    plugins: "advlist hr visualblocks media link image table textcolor colorpicker autoresize",
    toolbar: "insertfile undo redo | styleselect | bold italic forecolor backcolor" +
    " | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image",
    textcolor_cols: "5",
    textcolor_rows: "5",
    autoresize_max_height: 600,
    language: 'pt_BR',
    entity_encoding : "raw"
};

base.constant('TinyMCEConfig', PageTinyMCEConfig);
