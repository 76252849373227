function EmployeeModel() {
    this.id = null;
    this.documentNumber = "";
    this.userName = "";
    this.password = "";
    this.name = "";
    this.otherDocument = "";
    this.birthDate = "";
    this.maritalStatus = "";
    this.gender = "";
    this.occupation = "";
    this.coverImage = null;
    this.address = {
        "zipCode": "",
        "streetName": "",
        "complement": "",
        "neighborhood": "",
        "number": "",
        "city": "",
        "state": "",
        "country": ""
    };

    this.email = "";
    this.site = "";
    this.environmentProfile = "";
    this.environmentProfileName = "";
    this.storeId = null;

    this.phone = {id: null, phone: "", isDefault: false};
    this.celPhone = {id: null, phone: "", isDefault: false};

    this.phones = [this.phone, this.celPhone];
    this.isChangingPassword = false;
    this.accessEnvironments = [];
}

EmployeeModel.prototype.fromService = function (data) {
    for(var i = 0; i < data.phones.length; i++) {
        if(parseInt(data.phones[i].type) === 1 && data.phones[i].isDefault) {
            this.celPhone = {id: data.phones[i].id, phone: data.phones[i].phone, type: 1, isDefault: data.phones[i].isDefault};
        } else if(parseInt(data.phones[i].type) === 3) {
            this.phone = {id: data.phones[i].id, phone: data.phones[i].phone, type: 3, isDefault: data.phones[i].isDefault};
        }
    }

    this.id = data.id;
    this.documentNumber = data.documentNumber;
    this.userName = data.userName;
    this.password = data.password;
    this.name = data.name;
    this.otherDocument = data.otherDocument;

    this.setBirthDate(data.birthDate);

    this.maritalStatus = data.maritalStatus;
    this.gender = data.gender;
    this.occupation = data.occupation;

    if (data.address) {
        this.address = {
            "zipCode": data.address.zipCode,
            "streetName": data.address.streetName,
            "complement": data.address.complement,
            "neighborhood": data.address.neighborhood,
            "number": parseInt(data.address.number),
            "city": data.address.city,
            "state": data.address.stateCode,
            "country": data.address.country
        };
    }

    this.email = data.recoveryEmail ? data.recoveryEmail : data.email;
    this.site = data.site;
    this.environmentProfile = data.environmentProfile;
    this.environmentProfileName = data.environmentProfileName;
    this.storeId = data.storeId;

    this.phones = [this.phone, this.celPhone];
    this.isChangingPassword = data.isChangingPassword;
    this.coverImage = data.picture;
    this.accessEnvironments = data.accessEnvironments;
    console.log(this);
};

EmployeeModel.prototype.setBirthDate = function (date) {
    if(date){
        this.birthDate = moment(date.date).format('DD/MM/YYYY');
    }
};