function ServiceRequestCreateController($scope, ServiceRequestService, Loader, SimpleDialog, $mdpDatePicker, $timeout, $mdDialog, copyServiceRequestService, AppGlobalVars)
{
    $scope.maxFileUploadSize = AppGlobalVars.getMaxFileUploadSize();
    self.toFilter = [];

    /*
     * Start / End Dates
     */
    self.minDate = moment();
    self.minEndDate = self.minDate.toDate();
    $scope.expectedEndDate = null;
    $scope.expectedStartDate = null;

    const daysOfWeek = {
        1: 'Domingo',
        2: 'Segunda-feira',
        4: 'Terça-feira',
        8: 'Quarta-feira',
        16: 'Quinta-feira',
        32: 'Sexta-feira',
        64: 'Sábado'
    };

    const mapWeekDays = {
        1: 0,
        2: 1,
        4: 2,
        8: 3,
        16: 4,
        32: 5,
        64: 6
    };

    function expandAccordion(index)
    {
        var accordions = document.querySelectorAll('.accordion-header');
        if (!angular.element(accordions.item(index)).hasClass('is-expanded')) {
            $timeout(function() {
                angular.element(accordions).eq(index).triggerHandler('click');
            });
        }
    }

    function getServiceTimeLimit()
    {
        var now = moment();

        if (!$scope.serviceRequest.timeLimit) {
            return now.set({
                'year': now.add(100, 'years').year(),
                'minute': now.add(5, 'minute').minute(),
                'second': '00'
            });
        }

        const timeLimit = $scope.serviceRequest.timeLimit.split(':');

        return now.set({
            'hour': timeLimit[0],
            'minute': timeLimit[1],
            'second': '00'
        });
    }

    /*
     * Categories and Schedules
     */
    function loadCategories() {
        Loader.show();
        return ServiceRequestService.getCategories().then(function(categories) {
            $scope.categories = categories;
        }).finally(Loader.hide);
    }

    function scheduleDetail(id)
    {
        if (!id) {
            return;
        }

        Loader.hide();
        Loader.show();

        ServiceRequestService.getScheduleById(id).then(function(schedule) {
            var prepare = [];

            $scope.serviceRequest.workScheduleId = schedule.id;

            self.toFilter = [];
            $scope.expectedEndDate = null;
            $scope.expectedStartDate = null;

            for (var index in schedule.schedule) {
                var day = schedule.schedule[index];

                day.name = daysOfWeek[day.day];

                if (day.inOut.length > 0) {
                    prepare.push(day);
                    self.toFilter[mapWeekDays[day.day]] = day.inOut.length;
                }
            }

            $scope.scheduleDetails = prepare;

            if (prepare.length > 0) {
                $scope.showScheduleDetails = true;
            }

            setDatesInSchedule();

            Loader.hide();
        });
    }

    function setDatesInSchedule() {
        $scope.availableHours = {};
        for (var i = 0; i < 7; i++) {
            $scope.availableHours[i] = [];
        }

        for(i = 0; i < $scope.scheduleDetails.length; i++) {
            $scope.scheduleDetails[i].exactDate = $scope.setDefaultStartDate($scope.scheduleDetails[i]);
            const weekday = mapWeekDays[$scope.scheduleDetails[i].day];
            $scope.availableHours[weekday] = $scope.scheduleDetails[i].inOut;
        }
    }

    $scope.setServiceRequestCategory = function(selectedCategory) {
        $scope.calendarFilterItems = {};

        if ($scope.selectedCategory.value.anualCalendarID) {
            Loader.show();

             loadCalendar($scope.selectedCategory.value.anualCalendarID, $scope.selectedCategory.value.id).then(function() {
                $scope.serviceRequest.categoryId = $scope.selectedCategory.value.id;
                $scope.selectedCategory.warnings = [];
                Loader.hide();
            }).catch(Loader.hide);
        }

        self.toFilter = [];
        $scope.expectedEndDate = null;
        $scope.expectedStartDate = null;
        $scope.serviceRequest.categoryId = $scope.selectedCategory.value.id;
        $scope.selectedCategory.warnings = [];

        setCategoryDetail();
    };

    function loadCalendar(calendarId, categoryId)
    {
        return ServiceRequestService.getCalendar(calendarId, categoryId).then(function(calendar) {
            for (var key in calendar.anualCalendarItems) {
                var item = calendar.anualCalendarItems[key];

                var year = parseInt(item.year);
                var month = parseInt(item.month);
                var day = parseInt(item.day);

                if (!$scope.calendarFilterItems.hasOwnProperty(year)) {
                    $scope.calendarFilterItems[year] = {};
                }

                if (!$scope.calendarFilterItems[year].hasOwnProperty(month)) {
                    $scope.calendarFilterItems[year][month] = {};
                }

                $scope.calendarFilterItems[year][month][day] = true;
            }

            self.minDate = getStartDate(calendar.anualCalendarItems[calendar.anualCalendarItems.length - 1]);
        });
    }

    function getCategory(item, id)
    {
        if (!Array.isArray(item)) {
            return (item.id === id) ? item : getCategory(item.children, id);
        }

        var child = null;
        for (var i = 0; !child && (i < item.length); i++) {
            child = getCategory(item[i], id);
        }

        return child;
    }

    function setCategoryDetail() {

        if (isCopy) {
            setOrientation($scope.selectedCategory.value);
            scheduleDetail($scope.selectedCategory.value.workScheduleId);
            return;
        }

        var category = getCategory($scope.categories, $scope.serviceRequest.categoryId);

        if (!category) {
            return;
        }

        if (!$scope.serviceRequest.description) {
            $scope.serviceRequest.description = category.description;
        }

        $scope.serviceRequest.workScheduleId = category.workScheduleId;
        $scope.serviceRequest.timeLimit = category.timeLimit;
        $scope.serviceRequest.daysLimit = category.daysLimit;
        $scope.serviceRequest.workScheduleId = category.workScheduleId;

        setOrientation(category);
        scheduleDetail(category.workScheduleId);
    }

    function setOrientation(category)
    {
        if (category.orientation) {
            category.orientation = category.orientation.replace(/(?:\r\n|\r|\n)/g, '<br />');
            $scope.selectedCategory.warnings.push(category.orientation);
        }

        if (category.timeLimit) {
            $scope.selectedCategory.warnings.push([
                'Limite de horário de agendamento de execução para hoje: ' + category.timeLimit,
                'Após este horário, esta requisição só poderá ser executada a partir de amanhã.'
            ].join('<br>'));
        }

        if (category.daysLimit) {
            $scope.selectedCategory.warnings.push([
                'Sua solicitação poderá ter no máximo ' + category.daysLimit + ' dias corridos para execução.'
            ].join());
        }

        $scope.serviceRequest.orientation = $scope.selectedCategory.warnings.join('|');
    }

    $scope.resetSelectedCategory = function(event)
    {
        event.stopImmediatePropagation();

        $scope.scheduleDetails = [];
        $scope.showScheduleDetails = false;
        $scope.selectedCategory = {
            value: {},
            warnings: []
        };
    };

    function filterStartDate(date)
    {
        var year = date.getFullYear();
        var month = (date.getMonth() + 1);
        var day = date.getDate();

        if ($scope.calendarFilterItems.hasOwnProperty(year) &&
            $scope.calendarFilterItems[year].hasOwnProperty(month) &&
            $scope.calendarFilterItems[year][month].hasOwnProperty(day)) {
            return true;
        }

        return filterEndDate(date);
    }

    function filterEndDate(date)
    {
        if (self.toFilter.length > 0) {
            return self.toFilter[moment(date).weekday()] == undefined;
        }
        return false;
    }

    $scope.filterEndDate = filterEndDate;

    function getHoursFromSchedule(type, date)
    {
        var scheduleTime = null;

        for (var i = 0; i < $scope.scheduleDetails.length; i++) {
            var schedule = $scope.scheduleDetails[i];

            if (!schedule.inOut.length) continue;

            if (type === 'in') {
                // First "Work Schedule In" dateTime
                scheduleTime = moment(schedule.inOut[0].in);
            } else {
                // Last "Work Schedule Out" dateTime
                scheduleTime = moment(schedule.inOut[schedule.inOut.length - 1].out);
            }

            if (date.day() === scheduleTime.day()) {
                // Found day of week that matches with "Work Schedule"
                break;
            }
        }

        if (!!scheduleTime) {
            date = date.set({
                hour: scheduleTime.get('hour'),
                minute: scheduleTime.get('minute')
            });
        }

        return date;
    }

    function getServiceHoursFormatted(type, date)
    {
        date = moment(date);

        var now = moment();
        var timeLimit = getServiceTimeLimit();

        if (date.isSame(now, 'day')) {
            date = date.set({
                hour: timeLimit.hour(),
                minute: timeLimit.minute()
            });
        } else {
            date = getHoursFromSchedule(type, date);
        }

        return date.format('DD/MM/YYYY HH:mm');
    }

    $scope.compareWorkScheduleHours = function(a, b)
    {
        if (a.type === 'number' || b.type === 'number') {
            return 0;
        }

        var aH = moment(a.value).format('HH:mm');
        var bH = moment(b.value).format('HH:mm');

        return aH === bH ? 0 : (aH > bH ? 1 : -1);
    };

    $scope.setDefaultStartDate = function(dayFromService, updateScope) {
        var today = moment();

        var toDate = mapWeekDays[dayFromService.day];

        var date = moment();

        date.day(toDate);

        if (today.isAfter(date) || today.weekday() == toDate) {
            date.add(7, 'day');
        }

        // TODO: Improve this. Maybe use the last unavailable date as base
        while (filterStartDate(date.toDate()) && date.year() == today.year()) {
            date.add(7, 'day');
        }

        if (date.year() != today.year()) {
            date = null;
        }

        if(updateScope) {
            $scope.expectedStartDate = date ? date.toDate() : null;
            self.minEndDate = $scope.expectedStartDate;

            $scope.expectedEndDate = null;
        } else return date ? date.toDate() : null;
    };

    function getPreviewDays()
    {
        var start = moment($scope.expectedStartDate).startOf('day');
        const end = moment($scope.expectedEndDate).startOf('day');
        const weeks = [];

        var weekday = 0;

        var week = [];

        var month = -1;

        while (weekday < start.weekday()) {
            var day = start.clone();
            day.add(weekday - start.weekday(), 'day');

            var isNewMonth = false;
            if (month != day.month()) {
                isNewMonth = true;
                month = day.month();
            }

            week.push({date: day, inInterval: false, isNewMonth: isNewMonth});
            weekday++;
        }

        var first = true;
        while (start.isBefore(end)) {

            var isNewMonth = false;

            if (month != start.month()) {
                isNewMonth = true;
                month = start.month();
            }

            week.push({date: start, inInterval: true && !filterEndDate(start.toDate()), isNewMonth: isNewMonth, first: first, last: false});

            if (start.weekday() == 6) {
                weeks.push(week);
                week = [];
            }

            start = start.clone();
            start.add(1, 'days');
            first = false;
        }

        var last = true;
        weekday = end.weekday();

        while ((7 - weekday)) {
            var day = end.clone();
            day.add(weekday - end.weekday(), 'day');

            var isNewMonth = false;
            if (month != day.month()) {
                isNewMonth = true;
                month = day.month();
            }

            week.push({date: day, inInterval: false, isNewMonth: isNewMonth, first: false, last: last});
            weekday++;
            last = false;
        }

        if (week.length) {
            weeks.push(week);
        }

        $scope.previewDays = weeks;
    }

    $scope.getWeekdayName = function(day) {
        return moment().weekday(day).format('dddd');
    };

    $scope.showStartDate = function(ev) {
        var serviceTimeLimit = getServiceTimeLimit();

        if (self.minDate.isSame(serviceTimeLimit, 'day') && self.minDate.isSame(serviceTimeLimit, 'month') && self.minDate.isAfter(serviceTimeLimit)) {
            self.minDate = serviceTimeLimit.add(1, 'day');
        }

        $mdpDatePicker($scope.expectedStartDate, {
            targetEvent: ev,
            minDate: self.minDate.toDate(),
            dateFilter: filterStartDate,
            titleLabel: 'Selecione a data de início'
        }).then(function(selectedDate) {
            $scope.expectedStartDate = selectedDate;
            $scope.expectedEndDate = null;

            self.minEndDate = moment(selectedDate).toDate();

            $scope.showEndDate(ev);
        });
    };

    $scope.showEndDate = function(ev) {
        var daysLimit = $scope.serviceRequest.daysLimit - 1;
        var maxDate = daysLimit > -1 ? moment(self.minEndDate).add(daysLimit, 'days') : null;

        if (!$scope.expectedEndDate && maxDate) {
            $scope.expectedEndDate = getMaxValidDate(self.minEndDate, maxDate.toDate());
        }

        $mdpDatePicker($scope.expectedEndDate, {
            targetEvent: ev,
            minDate: self.minEndDate,
            maxDate: maxDate,
            dateFilter: filterStartDate,
            titleLabel: 'Selecione a data de término',
            intervalHighlight: true
        }).then(function(selectedDate) {
            $scope.expectedEndDate = selectedDate;
        }).finally(getPreviewDays);
    };

    function getMaxValidDate(minDate, maxDate)
    {
        minDate = moment(minDate);
        while (filterEndDate(maxDate)) {
            maxDate = moment(maxDate).add(-1, 'day').toDate();

            if (!minDate.isBefore(moment(maxDate))) {
                return null;
            }
        }

        return maxDate;
    }

    function getStartDate(lastUnavailableDate)
    {
        var minAvailableDateString = lastUnavailableDate.day + '/' + lastUnavailableDate.month + '/' + lastUnavailableDate.year;
        return moment(minAvailableDateString, 'DD/MM/YYYY').add('days', 1);
    }

    function showInvalidDatesDialog(message)
    {
        return SimpleDialog.show('Atenção', message, 'Ok').then(function() {

            // Open "Data de execução" accordion
            expandAccordion(1);
        });
    }

    function validateStartDate()
    {
        var date = moment($scope.expectedStartDate);

        if (!date.isValid()) {
            showInvalidDatesDialog('Preencha a data e horário de início prevista.');
            return false;
        }

        var now = moment();
        if (date.isSame(now, 'day') && now.hour() > getServiceTimeLimit().hour()) {
            showInvalidDatesDialog('A data deve ser maior que o dia de hoje.');
            return false;
        }

        return true;
    }

    function validateEndDate()
    {
        var dateStart = moment($scope.expectedStartDate);
        var dateEnd = moment($scope.expectedEndDate);

        if (!dateEnd.isValid()) {
            showInvalidDatesDialog('Preencha a data e horário de término prevista.');
            return false;
        }

        if (dateEnd.isValid() && dateStart.isValid()) {
            if (dateEnd.isBefore(dateStart, 'day')) {
                showInvalidDatesDialog('A data de término deve ser maior que a de início.');
                return false;
            }

            var now = moment();
            if (dateEnd.isSame(now, 'day') && now.hour() > getServiceTimeLimit().hour()) {
                showInvalidDatesDialog('A data deve ser maior que o dia de hoje.');
                return false;
            }
        }

        return true;
    }

    /*
     * Person
     */
    $scope.searchPerson = function() {
        var documentType = $scope.person.documentType;
        var document = $scope.person.document;
        var dateOfBirth = $scope.person.dateOfBirth;

        if (documentType === 'cpf' && document && document.length === 11 && dateOfBirth && dateOfBirth.length === 10) {
            Loader.show();

            ServiceRequestService.getPersonPromise(document, dateOfBirth, Loader.hide).then(function(result) {
                if (result.status != 200) {
                    throw (result.status);
                }

                $scope.person.name = result.data.data.name;

            }).finally(Loader.hide);
        }
    };

    $scope.addPerson = function (person) {
        if (!person.document || !person.name) {
            SimpleDialog.show('Atenção', 'Os campos documento e nome são obrigatórios.', 'Ok');
        } else {
            $scope.people.push(person);
        }

        $scope.person = {
            // Get last document type, usually the person list doesn't mix multiple document types
            documentType: person.documentType,
            document: null,
            name: null,
            phone: null,
            brand: null,
            model: null,
            plate: null,
            color: null,
            year: null
        };
    };

    /*
     * Form person modal
     */
    $scope.addPersonMd = function() {
        $mdDialog.show({
            templateUrl: 'app/modules/serviceRequest/views/add-person.html',
            parent: angular.element(document.body),
            clickOutsideToClose: true,
            fullscreen: true,
            controller: ServiceRequestPersonController
        }).then(function (person) {
            if (person !== undefined){
                $scope.people.push(person);
            }
        });
    };

    $scope.deletePerson = function(index) {
        $scope.people.splice(index, 1);
    };

    /*
     * Attachments
     */
    $scope.onAttachmentUpload = function (file, maxSize) {
        if (file.size <= maxSize) {
            var attachment = new ServiceRequestAttachmentModel();
                attachment.name = file.name;
                attachment.file = file;

            $scope.serviceRequest.attachments.push(attachment);

        } else {
            SimpleDialog.show('Ops', 'Tente novamente, tamanho máximo permitido ' + $scope.maxImageUploadSize.megabytes + 'MB.', 'Ok');
        }
    };

    $scope.removeAttachment = function ($index) {
        $scope.serviceRequest.attachments.splice($index, 1);
    };

    /*
     * Form actions
     */
    $scope.save = function(form) {
        form.$setSubmitted();

        if (!validateStartDate() || !validateEndDate()) return;

        if(form.$invalid) {
            angular.element(document.querySelector('input.ng-invalid'))[0].focus();
            return;
        }

        if (!!$scope.people) {
            angular.forEach($scope.people, function (person) {
                var personModel = new PersonServiceRequestModel();
                personModel.setData(person);
                $scope.serviceRequest.authorizedPersons.push(personModel);
            });
        }

        $scope.serviceRequest.expectedStartDateTime = getServiceHoursFormatted('in', $scope.expectedStartDate);
        $scope.serviceRequest.expectedFinishDateTime = getServiceHoursFormatted('out', $scope.expectedEndDate);

        Loader.show();

        var data = $scope.serviceRequest.toFormData();
        var promise = ServiceRequestService.create(data);

        promise.then(function() {
            SimpleDialog.show('Sucesso!', 'Sua requisição de serviço foi cadastrada com sucesso!', 'Ok').then(function(){
                $scope.route('/requisicao-de-servicos');
                if (isCopy) {
                    copyServiceRequestService.clear();
                }
            });
        });

        promise.catch(function(response) {
            var errorMessage = response.data.code === 406 ? response.data.message : 'Ocorreu um erro durante o cadastro da sua requisição, tente novamente mais tarde.';
            SimpleDialog.show('Ops!', errorMessage, 'Ok');
        });

        promise.finally(Loader.hide);
    };

    $scope.cancel = function() {
        $scope.route('/requisicao-de-servicos');
        copyServiceRequestService.clear();
    };

    function startCopy() {
        var copyServiceRequest = copyServiceRequestService.getServiceRequest();
        $scope.serviceRequest = new ServiceRequestModel();
        $scope.serviceRequest.fromService(copyServiceRequest);
        isCopy = true;
        $scope.warnnig = false;

        $scope.selectedCategory.value = $scope.serviceRequest.categoryId;
        $scope.people = $scope.serviceRequest.authorizedPersons;
        $scope.serviceRequest.authorizedPersons = [];
        $scope.selectedCategory.warnings = [];

        if ($scope.serviceRequest.attachments.length) {
            $scope.warnnig = true;
            $scope.serviceRequest.attachments = [];
        }

        $scope.setServiceRequestCategory($scope.selectedCategory);
        copyServiceRequestService.clear();

        setTimeout(function () {
            if ($scope.people.length) {
                expandAccordion(2);
                expandAccordion(3);
            }

        }, 3000);
    }

    $scope.scheduleDetails = [];
    $scope.showScheduleDetails = false;
    $scope.expandedPersons = '';
    $scope.selectedCategory = {
        value: {},
        warnings: []
    };

    $scope.people = [];
    var isCopy = false;

    /*
    * Defaults
    */
    loadCategories().then(function() {
        if (copyServiceRequestService.getServiceRequest() !== null) {
            startCopy();
        } else {
            $scope.serviceRequest = new ServiceRequestModel();
            isCopy = false;

            $scope.person = {
                documentType: 'rg',
                document: null,
                dateOfBirth: null,
                name: null,
                phone: null,
                brand: null,
                model: null,
                plate: null,
                color: null,
                year: null
            };

            $scope.selectedCategory = {
                value: {},
                warnings: []
            };
        }
    });
}

serviceRequest.controller('ServiceRequestCreateController', ServiceRequestCreateController);
