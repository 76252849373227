base.directive('treeViewSelect', [function () {

    function TreeViewSelectController($scope) {
        $scope.toggle = function () {
            items.toggleVisibility();
        }
    }

    TreeViewSelectController.prototype.setItems = function (items) {
        this.items = items;
    };

    return {
        restrict: 'E',

        transclude: true,

        scope: {
            values: '=',
            model: '='
        },

        controller: ['$scope', TreeViewSelectController],

        templateUrl: 'app/modules/base/views/directives/TreeViewSelect.html'
    };

}]);

base.directive('treeViewItem', [function () {

    function isFunction(functionToCheck) {
        var getType = {};
        return functionToCheck && getType.toString.call(functionToCheck) === '[object Function]';
    }

    return {
        restrict: 'E',
        scope: {
            item: '=value',
            model: '=',
            expanded: "@expanded",
            checkbox: "@checkbox",
            onChange: "=onChange"
        },
        controller: ['$scope', function ($scope) {
            if (!$scope.checkbox){
                $scope.checkbox = false;
            }

            if (!$scope.expanded) {
                $scope.expanded = false;
            }

            $scope.isChecked = function(item) {
                return !!$scope.model.value && $scope.model.value.id === item.id;
            };

            $scope.toggle = function () {
                $scope.expanded = !$scope.expanded;
            };

            $scope.change = function (item) {
                if ($scope.model.value && $scope.model.value.id === item.id) {
                    $scope.model.value = false;

                } else {
                    $scope.model.value = false;
                    $scope.model.value = item;
                }

                if (isFunction($scope.onChange)) {
                    $scope.onChange(item);
                }
            };
        }],
        templateUrl: 'app/modules/base/views/directives/TreeViewItem.html'
    };
}]);