'use strict';

shop.factory('Segments', ['$http', function($http) {

        return {
            getAll: function() {
                return $http.get('/mocks/shop/segments.json').then(function(data) {
                    return data.data;
                });
            }
        };
}]);