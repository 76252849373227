profile.factory('Profiles', ['$http', 'Middleware', function($http, Middleware) {

    var services = {
        profile: Middleware.getUrl('access-level')
    };

    function getAll() {
        return $http.get(services.profile, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    return {
        getAll: getAll
    };

}]);