'use strict';

base.controller('LeftController', function($scope, $mdSidenav) {

	
	$scope.toggleLeft = function () {
		$mdSidenav('left').toggle();
	}

});
