base.directive('validateCpf', function() {
    return {
        restrict: 'A',
        require: ['ngModel'],
        link: function($scope, $element, $attributes, ngModel) {
            return true;
            // function verifyCommonInvalidCases(value) {
            //     for(var i = 0; i < 11; i++) {
            //         if(value[0] != value[i]) {
            //             return true;
            //         }
            //     }
            //     return false;
            // }
            //
            // function verify(value, size, verifier) {
            //     var sum = 0;
            //     for(var i = 0, weight = size + 1; i < size; i++, weight--) {
            //         sum += value[i] * weight;
            //     }
            //     var digit = 11 - (sum % 11);
            //     digit = digit > 9? 0 : digit;
            //     return digit == verifier;
            // }
            //
            // ngModel[0].$validators.validateCpf = function(value) {
            //     if(!value || value.length < 11) {
            //         return false;
            //     }
            //     return verifyCommonInvalidCases(value) && verify(value, 9, value[9]) && verify(value, 10, value[10]);
            // }
        }
    };
});