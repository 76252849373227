product.factory('Products', ['$http', 'Middleware', function($http, Middleware) {
    const services = {
        product: Middleware.getUrl('product/')
    };

    var formDataRequestOptions = {transformRequest: angular.identity, headers: {'Content-Type': undefined}};

    function create(product) {
        return $http.post(services.product, product, formDataRequestOptions).then(function (response) {
            return response;
        });
    }

    function edit(id, product) {
        return $http.post(services.product + id, product, formDataRequestOptions).then(function (response) {
            return response;
        });
    }

    function getAll(limit, page, order, filter) {
        var url = services.product + 'list/' + limit + '/' + page + '?order='+ order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url, {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    function getById(id) {
        return $http.get(services.product + 'get/' + id).then(function (response) {
            var product = new ProductModel();
            product.fromService(response.data.data);
            return product;
        });
    }

    function remove(id) {
        return $http.delete(services.product + id);
    }

    return {
        create: create,
        edit: edit,
        getAll: getAll,
        getById: getById,
        remove: remove
    };
}]);