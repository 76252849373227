'use strict';

base.directive('ssUploadModel',  ['$parse', function($parse) {

    return {
        restrict: 'A',
        link: function($scope, $element, $attrs) {

            var model = $parse($attrs.ssUploadModel);
            var assign = model.assign;

            $element.bind('change', function() {
                if($element[0].files.length > 0) {
                    var file = $element[0].files[0];
                    var reader = new FileReader();

                    reader.onloadend = function() {
                        assign($scope, reader.result);
                        $element[0].value = null;
                        $scope.$apply();
                    };

                    reader.readAsDataURL(file);
                }
            });
        }
    }

}]);
