base.factory('User', ['$http', 'SessionStorage', 'Middleware', '$localStorage', function($http, SessionStorage, Middleware, $localStorage) {
    var STORAGE_USER_KEY = "shopkeeper-user";

    var user = null;

    var state = {
        logged: false,
        username: null,
        stores : [],
        menu: {value: null},
        changingPassword: null,
        options : [],
        firstName : null,
        permissions : null
    };

    var stores = [];

    var menu = [];

    /***
     * Services url
     * @type {{login: string}} Login service endpoint
     */
    var services = {
        login: Middleware.getUrl('user/login'),
        loginWithStore: Middleware.getUrl('user/login/set-store/'),
        logout: Middleware.getUrl('user/logout'),
        recoveryPassword: Middleware.getUrl('user/recovery-password'),
        changePassword: Middleware.getUrl('user/recovery-password-logged')
    };

    /***
     * Check for user permission on specific module and action
     * Returns false when no permission is defined for the
     * desired module and action
     * @param module Desired module
     * @param action Desired action
     * @returns {boolean} Has permission
     */
    function getPermission(module, action) {
        if(user && state.permissions) {
            if(state.permissions.hasOwnProperty(module) &&
               state.permissions[module].hasOwnProperty(action)) {
                return state.permissions[module][action];
            }
        }
        return false;
    }

    /***
     * Get current user permissions
     * @returns {Object} Object containing user permissions
     */
    function getPermissions() {
        if(user) { return state.permissions; }
        return null;
    }

    function getUserMenu() {
        if (user) {
            return user.menu;
        }
        return null;
    }

    /***
     * Get current user and sets authorization header
     * @returns {Object} User object
     */
    function getUser() {
        if(!user) {
            user = SessionStorage.getValue(STORAGE_USER_KEY);

            if(user) {
                $http.defaults.headers.common.Authorization = user.authorization;
                state.id = user.id;
                state.logged = true;
                state.username = user.name;
                state.storeName = user.loggedStoreName;
                state.storeId = user.loggedStoreId;
                state.menu.value = $localStorage.shopkeeperMenu;
                state.stores = $localStorage.stores;
                state.changingPassword = user.isChangingPassword;
                state.options = $localStorage.shopkeeperOptions;
                state.firstName = user.name.replace(/ .*/,'');
                state.permissions = $localStorage.permissions;
            }
        }

        return user;
    }

    /***
     * Verify if an user is logged in current session
     * @returns {boolean}
     */
    function isLogged() {
        if(this.getUser() != undefined){
            return this.getUser().loggedStoreId != null;
        }

        return null;
    }

    /***
     * Request login into service and stores user on session if successful
     * @param user User data for auth
     * @returns {Object} Promisse for when service request has finished
     */
    function login(user) {
        if(this.isLogged()){
            logout();
        }

        var params = JSON.stringify({username: user.email, password: user.password});

        return $http.post(services.login, params).then(function(data) {
            var storesReturned = data.data.data.stores;
            stores = [];

            for(var i in storesReturned){
                if(storesReturned[i].isActive){
                    stores.push(storesReturned[i]);
                }
            }

            if(data.data.data.stores.length > 0){
                $http.defaults.headers.common.Authorization = data.data.data.authorization;

            } else {
                menu = data.data.data.menu;
                var options = data.data.data.options;
                var stores = data.data.data.stores;
                var permissions = data.data.data.permissions;

                data.data.data.menu = [];
                data.data.data.options = [];
                data.data.data.stores = [];
                data.data.data.permissions = [];

                SessionStorage.setValue(STORAGE_USER_KEY, data.data.data);

                $localStorage.shopkeeperMenu = menu;
                $localStorage.shopkeeperOptions = options;
                $localStorage.stores = stores;
                $localStorage.permissions = permissions;

            }

            $localStorage.AppGlobalVars = data.data.data.globalAdminSiteVars;

            return stores;
        }).catch(function() {
            $http.defaults.headers.common.Authorization = null;
            return false;
        });
    }

    /***
     * Destroy current session and remove authorization token from headers
     */
    function logout() {
        return $http.post(services.logout).finally(function(data) {
            user = null;
            state.logged = false;
            state.username = null;
            state.storeName = null;
            state.stores = [];
            state.menu.value = [];
            state.changingPassword = null;
            state.shopkeeperOptions = [];
            state.storeId = null;
            state.permissions = null;

            SessionStorage.clear();
            $localStorage.$reset();
            delete $http.defaults.headers.common.Authorization;

            return true;
        });
    }
    
    function setStore(storeId)
    {
        var promisse = $http.post(services.loginWithStore + storeId).then(function(data) {
            if(user){
                state.logged = true;
                state.username = data.data.data.name;
                state.storeName = data.data.data.loggedStoreName;
                state.storeId = data.data.data.loggedStoreId;
                state.menu.value = data.data.data.menu;

                $http.defaults.headers.common.Authorization = data.data.data.authorization;

                user = data.data.data;
            }

            var menu = data.data.data.menu;
            var options = data.data.data.options;
            var stores = data.data.data.stores;
            var permissions = data.data.data.permissions;

            data.data.data.menu = [];
            data.data.data.options = [];
            data.data.data.stores = [];
            data.data.data.permissions = [];

            SessionStorage.setValue(STORAGE_USER_KEY, data.data.data);

            $localStorage.shopkeeperMenu = menu;
            $localStorage.shopkeeperOptions = options;
            $localStorage.stores = stores;
            $localStorage.permissions = permissions;

            return true;
        }).catch(function() {
            return false;
        });

        return promisse.then(function(data){
            return data;
        });
    }

    function getState() {
        return state;
    }

    function recoveryPassword(username) {
        return $http.post(services.recoveryPassword, username).then(function (data) {});

    }

    function changePassword(userPassword) {
        return $http.post(services.changePassword, userPassword).then(function (data) {});
    }
    
    return {
        getPermission: getPermission,
        getPermissions: getPermissions,
        getUser: getUser,
        isLogged: isLogged,
        login: login,
        logout: logout,
        setStore: setStore,
        getUserMenu: getUserMenu,
        getState: getState,
        recoveryPassword: recoveryPassword,
        changePassword: changePassword,
        state: state
    };

}]);