showcase.controller('ShowcaseEditItemController', ['$scope', '$window', '$routeParams', 'Showcase',
    function ($scope, $window, $routeParams, Showcase) {

        $scope.item = {
            key_words: [],
            date_in: new Date(),
            date_out: new Date()
        };

        var id = $routeParams.id;

        Showcase.getItemById(id).then(function (data) {
            $scope.item = data;
        });

        $scope.save = function (form) {

            form.$setSubmitted();

            if (form.$valid) {
                Showcase.editItem(id, $scope.item);
            } else {

            }

        };

        $scope.cancel = function () {
            $window.history.back();
        }

    }
]);