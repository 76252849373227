/***
 * Application state service
 * Used to share data across controllers and services
 * The data is shared using a key value map approach
 */
base.factory('State', function() {

    /**
     * State map
     * @type {Object} Map object containing data of application state
     */
    var state = {};

    /***
     * Verify if a value is contained in state
     * @param key
     * @returns {boolean}
     */
    function contains(key) {
        return state.hasOwnProperty(key);
    }

    /***
     * Retrieve a value from application state
     * @param key
     * @returns {*}
     */
    function getValue(key) {
        return state[key];
    }

    /***
     * Remove a value from application state
     * @param key
     */
    function remove(key) {
        delete state[key];
    }

    /***
     * StoreModel a value in application state
     * @param key Identifier for value. Reserved _@ prefix for internal use
     * @param value
     */
    function setValue(key, value) {
        state[key] = value;
    }

    return {
        contains: contains,
        getValue: getValue,
        remove: remove,
        setValue: setValue
    };

});