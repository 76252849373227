app.factory('DateConversor', function() {
    return {
        toDate: function(value) {
            value = value.split('.');
            var date = value[0].replace(/\-/g, '/');
            return new Date(date);
        }
    };
});

app.filter('dateObject', ['DateConversor', function (DateConversor) {
    return function (value) {
        if(value) {
            return DateConversor.toDate(value);
        }
        return '';
    }
}]);