app.directive('sidemenu', function (User, $location, $timeout, GridFilters) {

    return {
        
        transclude: true,

        link: function ($scope, $element, $attr, $ctrl, $transclude) {
            $scope.userState = User.getState();
            $scope.onAnimating = false;

            $scope.url = function (url, clearGridFilters) {
                if (clearGridFilters) {
                    GridFilters.deleteAll();
                }

                $location.path(url);
            };

            $scope.toggle = function (event, index) {

                event.stopPropagation();

                if(!$scope.onAnimating) {
                    $scope.onAnimating = true;
                    $timeout(function() {
                        $scope.onAnimating = false;
                    }, 500);

                    $scope.userState.menu.value[index].expanded = !$scope.userState.menu.value[index].expanded;
                }
            };

            $transclude($scope, function (clone) {
                $element.append(clone);
            });
        }
    }
});