base.directive('formDateValidate', [function () {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            ngModel:'='
        },
        link: function (scope, ele, attrs, ngModel) {
            ngModel.$validators.formDateValidate = function (modelValue) {
                if(modelValue.length === 0){
                    return true;
                }

                var date = moment(modelValue, "DDMMYYYY");
                var valid = date.isValid();

                if(valid){
                    scope.ngModel = date.format('DD/MM/YYYY');
                }

                return valid;
            }
        }
    }
}]);