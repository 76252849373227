function BaseLoginController($scope, $location, $route, $timeout, User, RouteManager, $mdDialog, Loader, Notifications)
{
    $scope.user = {};
    $scope.userRecovery = {};
    $scope.userStore = false;
    $scope.hideRecovery = false;
    $scope.hideLogin = true;
    $scope.alertSuccess = false;
    $scope.alertFail = false;
    $scope.message = '';
    $scope.loading = false;

    function choseStore(stores){
        function ModalController($scope, $mdDialog, stores, User)
        {
            $scope.stores = stores;

            $scope.close = function() {
                User.logout();
                $mdDialog.cancel();
            };

            $scope.selectStore = function(storeId) {
                $mdDialog.hide(storeId);
            }
        }

        var locals = {
            stores: stores
        };

        var dialog = {
            clickOutsideToClose: false,
            controller: ModalController,
            locals: locals,
            preserveScope: false,
            templateUrl : 'app/modules/base/views/choseStore.html'
        };

        return $mdDialog.show(dialog).then(function(value) {
            return value;
        });
    }

    function login() {
        $scope.loginForm.$setSubmitted();

        if ($scope.loginForm.$invalid) {
            return;
        }

        $scope.loading = true;
        Loader.show();

        var userPromisse = User.login($scope.user).then(function(success) {
            if(success) {
                if(success.length == 0){
                    return true;
                }else{
                    Loader.hide();
                    return choseStore(success).then(function(storeId){
                        if (storeId == undefined){
                            User.logout();
                        }else{
                            Loader.show();
                            return User.setStore(storeId).then(function(storeSuccess){
                                return !!storeSuccess;
                            });
                        }
                    });
                }
            }
            
            $mdDialog.show(
                $mdDialog.alert()
                    .parent(angular.element(document.querySelector('#popupContainer')))
                    .clickOutsideToClose(true)
                    .title('Tente novamente!')
                    .textContent('Usuário ou senha inválidos.')
                    .ariaLabel('Invalid credentials')
                    .ok('OK!')
            );
            
            return false;
        }).finally(function(){
            $scope.loading = false;
            Loader.hide();
        });

        userPromisse.then(function(success){
            if(success){
                RouteManager.provideRoutes();
                Notifications.getNotification(1, true);
                $location.path('/');
                $timeout($route.reload(), 0);
            }

            Loader.hide();
        });
    }

    $scope.login = login;

    $scope.onKeyPress = function (event) {
        if (event.keyCode == 13){
            login();
        }
    };
    
    $scope.sendUserName = function () {
        Loader.show();
        var promise = User.recoveryPassword($scope.userRecovery);

        promise.then(onAlertSuccess);

        promise.finally(function(){
            Loader.hide();
        });

        promise.catch(onAlertFail);
    };
    
    function onAlertSuccess() {
        $scope.alertSuccess = true;
        $scope.hideRecovery = false;
        $scope.message = "Enviamos uma nova senha para seu e-mail!"
    }

    function onAlertFail() {
        $scope.alertFail = true;
        $scope.hideRecovery = false;
        $scope.message = "Ocorreu um erro, tente novamente mais tarde!";
    }

    $scope.sendUserRecovery = function () {
        $scope.hideRecovery = true;
        $scope.hideLogin = false;
    };

    $scope.backLogin = function () {
        $scope.hideRecovery = false;
        $scope.hideLogin = true;
    };

    $scope.redirect = function (url) {
        $scope.alertSuccess = false;
        $scope.alertFail = false;
        $scope.hideLogin = true;
    };
}

base.controller('BaseLoginController',
    ['$scope', '$location', '$route', '$timeout', 'User', 'RouteManager', '$mdDialog', 'Loader', 'Notifications', BaseLoginController]);

function LogoutController($location, User, RouteManager, Loader, Menu,PageInformation)
{
    Loader.show();
    Menu.clear();
    PageInformation.setPageHeader(null);
    
    User.logout().then(function(){
        RouteManager.provideRoutes();

        $location.path('/login');
        Loader.hide();
    });
}

base.controller('LogoutController', ['$location', 'User', 'RouteManager','Loader', 'Menu','PageInformation', LogoutController]);