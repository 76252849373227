function DateTimeValidate() {
    return {
        restrict: 'A',
        require: 'ngModel',
        link: function ($scope, $element, $attributes, ngModel) {
            ngModel.$validators.dateTimeValidate = function (value) {
                if (!value) return true;
                return moment(value, 'DD/MM/YYYY HH:mm').isValid();
            }
        }
    }
}

base.directive('dateTimeValidate', [DateTimeValidate]);
