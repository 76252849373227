function ImageCropperUploadController($scope, AppGlobalVars, $attrs) {
    if(!$scope.fieldName){
        $scope.fieldName = 'Imagem';
    }

    if($scope.withImageFilters === undefined || $scope.withImageFilters == 'false') {
        $scope.withImageFilters = false;
    } else {
        $scope.withImageFilters = true;
    }

    if($scope.requiredField === undefined || $scope.requiredField == 'false') {
        $scope.requiredField = false;
    } else {
        $scope.requiredField = true;
    }

    $scope.coverImageUpdate = function(param) {
        $scope.content.coverImageCroppedAndFiltered = param;
    };

    $scope.finalImageUpdate = function(param) {
        $scope.content.finalImage = param;
    };

    $scope.id = 'image';

    if($attrs.id){
        $scope.id = $attrs.id;
    }

    $scope.maxImageUploadSize = AppGlobalVars.getMaxImageUploadSize();
}

function ImageCropperUpload() {
    return {
        restrict: 'E',
        controller: ImageCropperUploadController,
        transclude: true,
        scope: {
            form: '=',
            hints: '=',
            content: '=',
            initImage: '=',
            fieldName: '@',
            withImageFilters: "@",
            requiredField: "@"
        },
        templateUrl: 'app/modules/base/views/directives/ImageCropperUpload.html'
    };
}

base.directive('imageCropperUpload', ImageCropperUpload);