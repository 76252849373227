function EventGuestSaveController($scope, Loader, $mdDialog, SimpleDialog, SearchService, StatesService, EventGuestService, $routeParams, PageInformation, EventService) {
    $scope.guest = new GuestModel();
    $scope.image = new ImageModel();
    $scope.initImage = false;
    $scope.states = StatesService.getAll();
    $scope.eventId = $routeParams.id;

    EventGuestService.setEventId($routeParams.id);

    EventService.getById($routeParams.id).then(function(data) {
        PageInformation.setPageHeader(data.eventName + ' - Novo Convidado');
    });

    Loader.show();
    EventGuestService.getAll(1, 1, null, '').then(function(data) {
        if(EventGuestService.storeInvitationInfo.invitationsSent >= EventGuestService.storeInvitationInfo.maxInvitations) {
            SimpleDialog.show('Convites esgotados', 'Já foram enviados os ' + EventGuestService.storeInvitationInfo.maxInvitations + ' convites', 'Ok').then(function () {
                $scope.url('eventos/convidados/' + $routeParams.id);
            });
        }
    }).finally(function(){
        Loader.hide();
    });

    function changeCep(){
        if ($scope.guest.address.zipCode) {
            Loader.show();

            var promise = SearchService.findAddress($scope.guest.address.zipCode);

            promise.then(function(data){
                if(data.length > 0 ){
                    var address = data[0];

                    $scope.guest.address = {
                        "zipCode": address.zipCode,
                        "streetName": address.streetName,
                        "complement": address.complement,
                        "neighborhood": address.neighborhood,
                        "number": parseInt(address.addressNumber),
                        "city": address.city,
                        "state": address.stateCode,
                        "country": ""
                    };
                }
            });

            promise.finally(function(){
                Loader.hide();
            });
        }
    }

    function changeDocument() {
        if (!!$scope.guest.documentNumber) {
            Loader.show();

            var document = $scope.guest.documentNumber.replace(/[^0-9]/g, '');

            EventGuestService.checkDocument(document)
                .then(function(data){
                    SearchService.getPersonByDocument(document)
                        .then(onUserLoad)
                        .catch(function (data) {
                            if (data.data.code === 406) {
                                $scope.guest.documentNumber = null;
                                SimpleDialog.show('Erro!', 'Não foi possível processar sua requisição no momento, tente novamente mais tarde!', 'Ok');
                            }
                        })
                        .finally(onRequestFinally);
                })
                .catch(function (data) {
                    if (data.data.code === 406) {
                        Loader.hide();
                        $scope.guest.documentNumber = null;
                        SimpleDialog.show('Não permitido!' , data.data.error, 'Ok');
                    }
                });
        }
    }

    function searchDocument() {
        if (!!$scope.guest.documentNumber && !!$scope.guest.birthDate) {
            SearchService.findCpf($scope.guest.documentNumber,$scope.guest.birthDate)
                .then(function(data){
                    $scope.guest.name = data.name;
                });
        }
    }

    function onFormSubmit(guestForm) {
        guestForm.$setSubmitted();

        if (guestForm.$invalid) {
            angular.element(document.querySelector('input.ng-invalid'))[0].focus();
            return;
        }

        Loader.show();

        var promise = EventGuestService.saveInvitation($scope.guest);

        promise
            .then(function () {
                SimpleDialog.show('Sucesso', 'Convite cadastrado com sucesso!', 'Ok').then(function () {
                    $scope.url('eventos/convidados/' + $routeParams.id);
                });
            }).catch(function (data) {
            if (data.data.code === 406) {
                SimpleDialog.show('Erro', 'Ocorreu um erro ao convidar, ' + data.data.data + '.', 'Ok');
            } else {
                SimpleDialog.show('Erro', 'Ocorreu um erro ao convidar, tente novamente mais tarde', 'Ok');
            }
        }).finally(function () {
            Loader.hide();
        });
    }

    function onUserLoad(user) {
        $scope.guest.fromService(user);
    }

    function onRequestFinally() {
        Loader.hide();
    }

    $scope.submitForm = onFormSubmit;
    $scope.changeCep = changeCep;
    $scope.searchDocument = searchDocument;
    $scope.changeDocument = changeDocument;
}

employee.controller('EventGuestSaveController', EventGuestSaveController);