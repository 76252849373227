product.factory('ProductCategories', ['$http', 'Middleware', function($http, Middleware) {
    const services = {
        product: Middleware.getUrl('product/category')
    };

    function getAll(limit, page, order, filter) {
        return $http.get(services.product+'/list/1000/1', {cancellable: true}).then(function (response) {
            return response.data.data.data;
        });
    }


    function getAllTree() {
        return $http.get(services.product+'/tree', {cancellable: true}).then(function (response) {
            return response.data.data;
        });
    }

    return {
        getAll: getAll,
        getAllTree: getAllTree
    };

}]);