showcase.controller('ShowcaseCreateItemController', ['$scope', '$window', 'Showcase',
    function ($scope, $window, Showcase) {

        $scope.item = {
            key_words: [],
            date_in: new Date(),
            date_out: new Date()
        };

        $scope.save = function (form) {

            form.$setSubmitted();

            if (Object.keys(form.$error).length > 0) {

            } else {
                Showcase.createItem($scope.item);
            }

        };

        $scope.cancel = function () {
            $window.history.back();
        }

    }
]);