showcase.factory('Showcase', ['$http', 'DateConversor', 'Middleware', function ($http, DateConversor, Middleware) {

    var services = {
        showcase: Middleware.getUrl('showcase/'),
        item: Middleware.getUrl('showcase/item/')
    };

    function createItem(item) {
        var json = JSON.stringify(item);

        return $http.post(services.item, json).then(function(data) {
            return data;
        });
    }

    function editItem(id, item) {
        var json = JSON.stringify(item);

        return $http.put(services.item.concat(id), json).then(function(data) {
            return data;
        });
    }

    function getAll() {
        return $http.get(services.showcase).then(function (result) {

            var showcase = null;

            if (result.data.cod === 200) {
                showcase = result.data.data;
            }

            return showcase;

        });
    }

    function getItemById(id) {
        return $http.get(services.item.concat(id)).then(function (result) {

            var item = null;

            if (result.data.cod === 200) {
                item = result.data.data;

                if (("date" in item.date_in)) {
                    item.date_in = DateConversor.toDate(item.date_in.date);
                }

                if (("date" in item.date_out)) {
                    item.date_out = DateConversor.toDate(item.date_out.date);
                }

            }

            return item;

        });
    }

    return {
        editItem: editItem,
        createItem: createItem,
        getAll: getAll,
        getItemById: getItemById
    };

}]);