function OpportunityModel() {
    this.id = null;
    this.storeId = '';
    this.name = '';
    this.amount = '';
    this.details = '';
    this.requirements = '';
    this.experience = '';
    this.salary = '';
    this.benefits = '';
    this.observation = '';
    this.contactName = '';
    this.contactEmail = '';
    this.contactPhone = '';
    this.active = true;
    this.approved = false;
    this.expirationDateTime = '';
    this.interestAreas = [];
    this.interestAreasDeleted = [];
    this.interestAreasPreview = [];
};

OpportunityModel.prototype.setExhibitionDateTime = function (date) {
    this.expirationDateTime = moment(date).format('DD/MM/YYYY HH:mm');
};

OpportunityModel.prototype.fromService = function (data) {
    this.id = data.id;
    this.storeId = data.storeId;
    this.name = data.name;
    this.amount = data.amount;
    this.details = data.details;
    this.requirements = data.requirements;
    this.experience = data.experience;
    this.salary = data.salary;
    this.benefits = data.benefits;
    this.observation = data.observation;
    this.contactName = data.contactName;
    this.contactEmail = data.contactEmail;
    this.contactPhone = data.contactPhone;
    this.active = data.active;
    this.approved = data.approved;
    this.interestAreasPreview = data.interestAreas;

    if (data.interestAreas != null) {
        this.interestAreas = OpportunityModel.prototype.getIdOpportunities(data.interestAreas);
    }else{
        this.interestAreas = [];
    }
    if (data.expirationDateTime != null) {
        this.setExhibitionDateTime(data.expirationDateTime.date);
    } else {
        this.expirationDateTime = '';
    }
};

OpportunityModel.prototype.getIdOpportunities = function (areas) {
    var aux = [];

    for(var x in areas){
        aux.push(areas[x].id);
    }

    return aux;
};



