function ProductListController($scope, $mdMedia, SimpleDialog, $mdDialog, $q, Products, Loader, GridFilters)
{
    $scope.selected = [];

    $scope.authorizationStatusMap = {
        0: {title: 'Produto foi criado sem definições de autorização no 4C', icon: 'info_outline', color: 'gray', label: ''},
        1: {title: 'Aguardando', icon: 'hourglass_empty', color: '', label: 'label-warning'},
        2: {title: 'Aprovado', icon: 'thumb_up', color: 'green', label: 'label-success'},
        3: {title: 'Não autorizadao', icon: 'thumb_down', color: 'red', label: 'label-danger'}
    };

    function getAuthorizationProperty(status, property) {
        if ($scope.authorizationStatusMap.hasOwnProperty(status)
            && $scope.authorizationStatusMap[status].hasOwnProperty(property)
        ) {
            return $scope.authorizationStatusMap[status][property];
        }

        return null;
    }

    $scope.getAuthorizationTitle = function(item) {
        return getAuthorizationProperty(item.authorizationStatus, 'title');
    };

    $scope.getAuthorizationColor = function(item) {
        return getAuthorizationProperty(item.authorizationStatus, 'color');
    };

    $scope.getAuthorizationIcon = function(item) {
        return getAuthorizationProperty(item.authorizationStatus, 'icon');
    };

    $scope.authorizationNotConfigured = function(item) {
        return parseInt(item.authorizationStatus) === 0;
    };

    $scope.isWaitingAuthorization = function(item) {
        return parseInt(item.authorizationStatus) === 1;
    };

    $scope.isMobile = function() {
        return $mdMedia('xs') || $mdMedia('sm');
    };

    function onDataLoad(Products)
    {
        $scope.items = {
            data: Products.data,
            count: Products.total,
            page: Products.currentPage,
            limit: Products.limit
        }
    }

    function onDataLoadError(error) {
        if (error) LoadErrorDialog.show('/');
    }

    function loadData(limit, page, order) {
        $scope.order = order;
        GridFilters.setPaginationSettings({limit: limit, page: page, order: order});
        GridFilters.setFilterSettings({showClearFilter: $scope.showClearFilter, searchFields: $scope.searchFields});

        var promise = Products.getAll(limit, page, order, prepareFilter());

        promise.then(onDataLoad);
        promise.catch(onDataLoadError);

        $scope.promise = promise;
    }

    function onDelete(event) {
        if ($scope.selected.length == 0) {
            showDeleteError(event);
            return;
        }

        showDeleteConfirm(event);
    }

    function deleteSelected(event) {
        var selected = $scope.selected;
        var size = selected.length;

        Loader.show();

        var promises = [];

        for (var i = 0; i < size; i++) {
            var item = selected[i];
            promises.push(Products.remove(item.id, $scope.storeId));
        }

        var promise = $q.all(promises);

        promise.then(function () {
            SimpleDialog.show('Sucesso', 'Registro(s) removido(s) com sucesso.', 'Ok', event);
        });

        promise.catch(function () {
            // TODO: Show which products weren't removed
            SimpleDialog.show('Ops', 'Um ou mais registros não foram removidos.', 'Ok', event);
        });

        promise.finally(function () {
            Loader.hide();
            loadData($scope.items.limit, $scope.items.page, $scope.order);
        });

        $scope.selected = [];
    }

    function showDeleteConfirm(event) {
        var confirm = $mdDialog.confirm()
            .title('Remover registros')
            .textContent('Tem certeza que deseja remover os registro(s) selecionado(s)?')
            .ariaLabel('Remover registros')
            .targetEvent(event)
            .ok('Remover')
            .cancel('Cancelar');

        var promise = $mdDialog.show(confirm);

        promise.then(function () {
            deleteSelected(event);
        });
    }

    function showDeleteError(event) {
        SimpleDialog.show('Nenhum registro selecionado', 'Selecione pelo menos um registro para remoção.', 'Ok', event);
    }

    function onReorder(order) {
        loadData($scope.items.limit, $scope.items.page, order);
    }

    function prepareFilter() {
        var filter = '';

        for (var index in $scope.searchFields) {
            if ($scope.searchFields[index] !== null && $scope.searchFields[index] !=='') {
                if (filter) {
                    filter += "&";
                }

                filter += index+"="+$scope.searchFields[index];
            }
        }

        if (filter == '') {
            $scope.showClearFilter = false;
        }

        return filter;
    }

    function search() {
        $scope.showClearFilter = true;

        loadData($scope.items.limit, $scope.items.page, $scope.order);
    }

    function clearFilter() {
        for (var index in $scope.searchFields) {
            $scope.searchFields[index] = null;
        }

        $scope.searchText = null;
        $scope.showClearFilter = false;

        loadData($scope.items.limit, $scope.items.page, $scope.order);
    }

    function onPaginate() {
        loadData($scope.items.limit, $scope.items.page, $scope.items.order);
    }

    function details($event, id) {
        Loader.show();
        var productPromise = Products.getById(id);

        productPromise.then(function(product) {
            const dialog = {
                fullscreen: true,
                clickOutsideToClose: true,
                targetEvent: $event,
                templateUrl: 'app/modules/product/views/product-details.html',
                controller: function (
                    $scope, $mdDialog, product, isMobile,
                    getAuthorizationTitle, getAuthorizationColor,
                    getAuthorizationIcon, authorizationNotConfigured,
                    isWaitingAuthorization
                ) {
                    product.categoriesName = [];

                    for (var i in product.previewCategories) {
                        product.categoriesName.push(product.previewCategories[i].name);
                    }

                    $scope.product = product;
                    $scope.getAuthorizationTitle = getAuthorizationTitle;
                    $scope.getAuthorizationColor = getAuthorizationColor;
                    $scope.getAuthorizationIcon = getAuthorizationIcon;
                    $scope.authorizationNotConfigured = authorizationNotConfigured;
                    $scope.isWaitingAuthorization = isWaitingAuthorization;

                    $scope.getAuthorizationStatusLabel = function(status) {
                        const label = getAuthorizationProperty(status, 'label');
                        const title = getAuthorizationProperty(status, 'title');

                        return '<span class="' + label + '">' + title + '</span>';
                    };

                    $scope.getDialogSize = function() {
                        switch (true) {
                            case $mdMedia('xs') || $mdMedia('sm'): return 80;
                            default: return 60;
                        }
                    };

                    $scope.isMobile = isMobile;

                    $scope.close = function() {
                        $mdDialog.hide(true);
                    };
                }
            };

            dialog.locals = {
                product: product,
                isMobile: $scope.isMobile,
                getAuthorizationTitle: $scope.getAuthorizationTitle,
                getAuthorizationColor: $scope.getAuthorizationColor,
                getAuthorizationIcon: $scope.getAuthorizationIcon,
                authorizationNotConfigured: $scope.authorizationNotConfigured,
                isWaitingAuthorization: $scope.isWaitingAuthorization
            };

            $mdDialog.show(dialog);
        });

        productPromise.catch(function() {
            SimpleDialog.show('Ops', 'Não foi possível carregar os detalhes do produto.', 'Ok', event);
        });

        productPromise.finally(function() {
            Loader.hide();
        });
    }

    $scope.clearSegmentSearch = '';

    $scope.showClearFilter = false;
    $scope.order = '-id';
    $scope.reorder = onReorder;
    $scope.search = search;
    $scope.clearFilter = clearFilter;
    $scope.paginate = onPaginate;
    $scope.onDelete = onDelete;
    $scope.details = details;

    const paginationSettings = GridFilters.getPaginationSettings({limit: 10, page: 1, order: '-id'});
    const filterSettings = GridFilters.getFilterSettings({showClearFilter: false, searchFields:{name: null, identifier: null, status: null}});

    $scope.showClearFilter = filterSettings.showClearFilter;
    $scope.searchFields = filterSettings.searchFields;

    loadData(paginationSettings.limit, paginationSettings.page, paginationSettings.order);
}

product.controller('ProductListController', ['$scope', '$mdMedia', 'SimpleDialog', '$mdDialog', '$q', 'Products',
    'Loader', 'GridFilters', ProductListController]);
