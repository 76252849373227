serviceRequest.factory('ServiceRequestService', ['$http', 'Middleware', function ($http, Middleware) {

    var services = {
        serviceRequest: Middleware.getUrl('service-request/'),
        categories: Middleware.getUrl('service-request/categories'),
        schedule: Middleware.getUrl('service-request/schedule/'),
        calendar: Middleware.getUrl('service-request/calendar/'),
        authorizationConfig: Middleware.getUrl('service-request/authorization-config'),
        observation: Middleware.getUrl('/serviceRequest/observation/'),
        cpf: Middleware.getUrl('search/cpf'),
    };

    const formDataRequestOptions = {transformRequest: angular.identity, headers: {'Content-Type': undefined}};

    function create(serviceRequest)
    {
        return $http.post(services.serviceRequest, serviceRequest, formDataRequestOptions).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data;
        });
    }

    function getAll(limit, page, order, filter)
    {
        var url = services.serviceRequest + "list/" + limit + "/" + page + '?order=' + order;

        if(filter){
            url += "&"+filter;
        }
        
        return $http.get(url).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getById(id)
    {
        return $http.get(services.serviceRequest +"get/" +id).then(function(result) {

            if(result.status != 200) {
                throw (result.status);
            }

            return result.data.data;
        });
    }

    function getCategories()
    {
        return $http.get(services.categories).then(function(result) {
            return result.data.data;
        });
    }

    function getSchedule()
    {
        return $http.get(services.schedule + "list").then(function(result) {
            return result.data.data;
        });
    }
    
    function getScheduleById(id)
    {
        return $http.get(services.schedule + "get/" + id).then(function(result) {
            return result.data.data;
        });
    }

    function getPersonPromise(cpf, dateOfBirth)
    {
        dateOfBirth = dateOfBirth.replace(/\//g, '-');

        return $http.get(services.cpf + "/" + cpf + "/" + dateOfBirth);
    }

    function getCalendar(calendarId, categoryId)
    {
        const params = {category: categoryId};
        return $http.get(services.calendar + calendarId, {params: params}).then(function(result) {
            return result.data.data;
        });
    }

    return {
        create: create,
        getAll: getAll,
        getById: getById,
        getCategories: getCategories,
        getSchedule: getSchedule,
        getScheduleById: getScheduleById,
        getPersonPromise: getPersonPromise,
        getCalendar: getCalendar
    };
}]);
