var events = angular.module('shopkeeperApp.events', []);

events.run(['RouteManager', function(RouteManager) {

    RouteManager.registerProvider(EventRouterManager);

    function EventRouterManager(route, User) {
        route
            .when('/eventos', {
                templateUrl: 'app/modules/events/views/list.html',
                controller: 'EventListController',
                pageName: 'Eventos',
                allow: User.getPermission('events', 'view') || true,
                name: 'events-list',
                breadcrumb: {
                    label: 'Eventos',
                    parent: 'base-index',
                    url: '/eventos'
                }
            })
            .when('/eventos/convidados/:id', {
                templateUrl: 'app/modules/events/views/event-guest/list-guest.html',
                controller: 'EventGuestListController',
                pageName: 'Convidados',
                allow: User.getPermission('events', 'view') || true,
                name: 'events-guest',
                breadcrumb: {
                    label: 'Convidados',
                    parent: 'events-list',
                    url: '/eventos/convidados/:id'
                }
            })
            .when('/eventos/convidados/:id/convidar', {
                templateUrl: 'app/modules/events/views/event-guest/guest-form.html',
                controller: 'EventGuestSaveController',
                pageName: 'Novo Convidado',
                allow: User.getPermission('events', 'view') || true,
                name: 'new-guest',
                breadcrumb: {
                    label: 'Novo Convidado',
                    parent: 'events-list',
                    url: '/eventos/convidados/:id'
                }
            });
    }
}]);