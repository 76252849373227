app.filter('phone', function() {
    return function(value) {
        if(value && value.length > 9) {
            var result = value.split('');

            result.splice(0, 0, '(');
            result.splice(3, 0, ') ');
            result.splice(8, 0, '-');

            return result.join('');
        }
        return '';
    }
});