function AppGlobalVars($localStorage) {

    function getMaxFileUploadSize() {
        return $localStorage.AppGlobalVars.maxFileUploadSize !== undefined ? $localStorage.AppGlobalVars.maxFileUploadSize : {bytes: '5242880', megabytes: '5'};
    }

    function getMaxImageUploadSize() {
        return $localStorage.AppGlobalVars.maxImageUploadSize !== undefined ? $localStorage.AppGlobalVars.maxImageUploadSize : {bytes: '5242880', megabytes: '5'};
    }

    return  {
        getMaxFileUploadSize: getMaxFileUploadSize,
        getMaxImageUploadSize: getMaxImageUploadSize
    };
}

base.factory('AppGlobalVars', ['$localStorage', AppGlobalVars]);