base.directive('treeViewMultiSelected', [function () {
    return {
        restrict: 'E',
        scope: {
            value: '=',
            model: '=',
            multiselected: "=",
            expanded: "@expanded",
            checkbox: "@checkbox",
            multicheck: "@multicheck",
            checkParents: '=',
            itensList: '='
        },
        controller: ['$scope', function ($scope) {
            $scope.checkParents = ($scope.checkParents || $scope.checkParents === 'true');

            if ($scope.checkbox === undefined){
                $scope.checkbox = false;
            }

            if ($scope.expanded === undefined) {
                $scope.expanded = false;
            }

            $scope.toggle = function () {
                $scope.expanded = !$scope.expanded;
            };

            $scope.markCheck = function (item) {
                var idx = $scope.multiselected.indexOf(item);

                if (idx > -1) {
                    $scope.multiselected.splice(idx, 1);
                }
                else if ($scope.multicheck === 'true' || $scope.multiselected.length < 1) {
                    $scope.multiselected.push(item);

                    markParent(item, false);
                }else {
                    $scope.multiselected.splice(0, 1);
                    $scope.multiselected.push(item);
                }
            };

            $scope.isChecked = function (item) {
                return $scope.multiselected.indexOf(item) > -1;
            };

            function markParent(segment, parent) {
                if($scope.checkParents) {
                    for(var i in $scope.itensList) {
                        if($scope.itensList[i].id === segment) {
                            if(!$scope.itensList[i]['parentMarked'] && parent) {
                                $scope.itensList[i]['parentMarked'] = parent;
                            }else {
                                $scope.itensList[i]['parentMarked'] = false;
                            }

                            if($scope.itensList[i].parentId) {
                                $scope.multiselected.push($scope.itensList[i].parentId);
                                markParent($scope.itensList[i].parentId, $scope.itensList[i].name);
                            }
                        }
                    }
                }
            }
        }],

        templateUrl: 'app/modules/base/views/directives/TreeViewItemMulti.html?v=2'
    };

}]);