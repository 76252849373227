base.directive('accordionGroup', [function () {
    return {
        restrict: 'E',
        transclude: true,
        template: '<div layout="row" layout-wrap flex="100" ng-transclude></div>',
        scope: {
            expandMultiple: '@'
        },

        controller: function($scope) {
            this.$accordions = [];

            this.addItem = function($accordion) {
                this.$accordions.push($accordion);
            };

            this.closeOthers = function($currentAccordion) {
                if ($scope.expandMultiple) {
                    return;
                }

                angular.forEach(this.$accordions, function ($accordion) {
                    if ($accordion !== $currentAccordion) {
                        $accordion.expanded = false;
                    }
                });
            };
        }
    };
}]);

base.directive('accordion', [function () {
    return {
        require: '^accordionGroup',
        restrict: 'E',
        transclude: true,
        scope: {
            title: '@',
            icon: '@',
            expanded: "@",
            toggleChange: "=change"
        },

        link: function($scope, element, attrs, accordionGroup) {
            accordionGroup.addItem($scope);

            $scope.toggle = function () {
                $scope.expanded = !$scope.expanded;
                accordionGroup.closeOthers($scope);
            };
        },

        controller: function ($scope) {
            if ($scope.expanded === undefined) {
                $scope.expanded = false;
            }
        },

        templateUrl: 'app/modules/base/views/directives/Accordion.html'
    };

}]);
