serviceRequest.factory('copyServiceRequestService', ['$http', function ($http) {
    var serviceRequest = null;

    function getServiceRequest()
    {
        return serviceRequest;
    }

    function setServiceRequest(param)
    {
        serviceRequest = param;
    }

    function clear() {
        serviceRequest = null;
    }

    function getAttachment(url)
    {
        return $http.get(url, {responseType: 'arraybuffer'}).then(function(response) {
            const blob = new Blob([response.data], {type: 'application/octet-stream'});
            return blob;
        });
    }

    return {
        setServiceRequest: setServiceRequest,
        getServiceRequest: getServiceRequest,
        clear: clear,
        getAttachment: getAttachment
    };
}]);
