function GuestModel() {
    this.id = null;
    this.documentNumber = "";
    this.name = "";
    this.otherDocument = "";
    this.birthDate = "";
    this.maritalStatus = "";
    this.gender = "";
    this.occupation = "";
    this.coverImage = null;
    this.address = {
        "zipCode": "",
        "streetName": "",
        "complement": "",
        "neighborhood": "",
        "number": "",
        "city": "",
        "state": ""
    };

    this.email = "";
    this.site = "";

    this.phone = {id: null, phone: "", isDefault: false};
    this.celPhone = {id: null, phone: "", isDefault: false};
    this.phones = [this.phone, this.celPhone];
    this.coverImage = "";
}

GuestModel.prototype.fromService = function (data)
{
    for(var i = 0; i < data.phones.length; i++) {
        if(parseInt(data.phones[i].type) === 1 && data.phones[i].isDefault) {
            this.celPhone = {id: data.phones[i].id, phone: data.phones[i].phoneNumber.replace(/[^0-9]/g, ''), type: 1, isDefault: data.phones[i].isDefault};
        } else if(parseInt(data.phones[i].type) === 3) {
            this.phone = {id: data.phones[i].id, phone: data.phones[i].phoneNumber.replace(/[^0-9]/g, ''), type: 3, isDefault: data.phones[i].isDefault};
        }
    }

    for(var i = 0; i < data.emails.length; i++) {
        if(data.emails[i].isDefault) {
            this.email = data.emails[i].email;
        }
    }

    this.id = data.id;
    this.documentNumber = data.documentNumber;
    this.name = data.name;
    this.otherDocument = data.otherDocument;

    if(data.day && data.month && data.year) {
        this.birthDate = moment(data.day + '/' + data.month + '/' + data.year, 'DD/MM/YYYY').format('DD/MM/YYYY');
    }

    this.maritalStatus = data.maritalStatus;
    this.gender = data.gender;
    this.occupation = data.occupation;

    if (data.addresses.length) {
        this.address = {
            "zipCode": data.addresses[0].zipCode,
            "streetName": data.addresses[0].address,
            "complement": data.addresses[0].complement,
            "neighborhood": data.addresses[0].neighborhood,
            "number": parseInt(data.addresses[0].number),
            "city": data.addresses[0].city,
            "state": data.addresses[0].state
        };
    }

    this.site = data.site;
    this.phones = [this.phone, this.celPhone];
    this.coverImage = data.pictureUri;
};