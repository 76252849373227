function ImageUpload($http, $timeout) {
    return {
        restrict: 'A',
        require: 'ngModel',
        scope: {
            imageHeight: "=imageHeight",
            imageWidth: "=imageWidth",
            imageUpload: '=',
            initCrop: '=',
            showSvg: '=',
            initImage: '='
        },
        link: function ($scope, $element, $attributes, ngModel) {
            var maxSize = $attributes.maxSize;
            var fileSize = true;
            var imageDimensions = true;
            var imageLoaded = false;

            $scope.$watch(function() {
                return $scope.imageHeight;
            }, function() {
                validateImageDimensions();
            });

            $scope.$watch(function() {
                return $scope.imageWidth;
            }, function() {
                validateImageDimensions();
            });

            $scope.$watch(function() {
                return $scope.imageUpload.image;
            }, function(value) {
                if($scope.initImage && value){
                    var srcImageData = document.querySelector('#imagePreview').src;

                    if(srcImageData){
                        $scope.initCrop = false;
                        $scope.showSvg = false;
                        $scope.imageUpload.coverImageCroppedAndFiltered = null;

                        $http.get(srcImageData, {cancellable: true}).then(function (response) {
                            if(response.headers('content-type') !== 'image/svg+xml'){
                                $scope.initCrop = true;
                            }else{
                                $scope.initCrop = false;
                                $scope.showSvg = true;
                            }

                            imageLoaded = true;
                            $scope.initImage = false;
                        });
                    }
                }
            });

            function validateImageDimensions(fileType) {
                if (!$scope.imageHeight || !$scope.imageWidth || fileType === 'image/svg+xml') {
                    $timeout(function() {
                        ngModel.$validate();
                        $scope.$parent.$apply();
                    });

                    return;
                }

                var image = new Image();

                image.onload = function() {
                    imageDimensions = (image.height >= $scope.imageHeight) && (image.width >= $scope.imageWidth);
                    ngModel.$validate();
                    $scope.$parent.$apply();
                };

                image.src = ngModel.$modelValue;
            }

            $element.bind('click', function() {
                ngModel.$setTouched();
            });

            $element.bind('change', function() {
                imageDimensions = true;

                if ($element[0].files.length > 0) {
                    var file = $element[0].files[0];
                    var reader = new FileReader();

                    if (file.size <= maxSize) {
                        reader.onloadend = function () {
                            fileSize = true;

                            $scope.imageUpload.image = reader.result;
                            $element[0].value = null;

                            $scope.initCrop = false;
                            $scope.showSvg = false;
                            $scope.imageUpload.coverImageCroppedAndFiltered = null;
                            $scope.$parent.$apply();

                            validateImageDimensions(file.type);

                            if(file.type !== 'image/svg+xml'){
                                $scope.initCrop = true;
                            }else{
                                $scope.initCrop = false;
                                $scope.showSvg = true;
                            }

                            imageLoaded = true;
                        };
                    } else {
                        fileSize = false;
                        imageLoaded = false;

                        $scope.imageUpload.image = null;
                        $scope.$parent.$apply();
                    }

                    reader.readAsDataURL(file);
                }
            });

            ngModel.$validators.imageDimensions = function() {
                if(!imageDimensions){
                    $scope.imageUpload.image = '';
                    $scope.imageUpload.url='';
                    $scope.imageUpload.coverImageCroppedAndFiltered = null;
                    $scope.initCrop = false;
                    imageLoaded = false;
                }

                return imageDimensions;
            };

            ngModel.$validators.fileSize = function() {
                return fileSize;
            };
        }
    }
}

base.directive('imageUpload', ImageUpload);
