function EventGuestService($http, Middleware) {
    var service = {
        url: Middleware.getUrl('store-invitation/')
    };
    var eventId = null;
    var storeInvitationInfo = {
        invitationsSent : 0,
        maxInvitations : 0
    };

    function getAll(page, limit, order, filter) {
        var url = service.url + eventId + "/list/" + limit + '/' + page + '?order='+ order;

        if(filter){
            url += "&"+filter;
        }

        return $http.get(url).then(function (response) {
            storeInvitationInfo.invitationsSent = response.data.data.invitationsSent;
            storeInvitationInfo.maxInvitations = response.data.data.maxInvitations;

            return response.data.data.invitations;
        });
    }

    function saveInvitation(person) {
        return $http.post(service.url + eventId + '/', JSON.stringify(person));
    }

    function checkDocument(document) {
        var url = service.url + eventId + "/check-document/" + document;

        return $http.get(url).then(function (response) {
            return response.data.data;
        });
    }

    function removePerson(eventId, documentNumber) {
        var document = documentNumber.replace(/[^0-9]/g, '');

        return $http.delete(service.url + eventId + '/remove-person/' + document);
    }

    function setEventId(id) {
        eventId = id;
    }

    return {
        saveInvitation: saveInvitation,
        getAll: getAll,
        setEventId: setEventId,
        checkDocument: checkDocument,
        removePerson: removePerson,
        storeInvitationInfo: storeInvitationInfo
    };
}

events.factory('EventGuestService', ['$http', 'Middleware', EventGuestService]);